import React, { useState, useEffect, useRef } from 'react';
import { TweenMax } from 'gsap/TweenMax';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// @import constants
import {
	lessonlist_endpoint,
	lessonarchive_endpoint,
} from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
import LessonAddEditPopup from 'js/components/popup/lessonAddEditPopup';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { getCurrentTopic } from 'js/utils/filterArray';
// @import styles
import './index.scss';
// @import svgs
import { ReactComponent as Add } from 'images/svg/add.svg';
import { ReactComponent as Edit } from 'images/svg/edit.svg';
import { ReactComponent as UpArrow } from 'images/svg/up-arrow.svg';
import { ReactComponent as Duplicate } from 'images/svg/duplicate.svg';
import { ReactComponent as Eye } from 'images/svg/eye.svg';
import { ReactComponent as EyeClosed } from 'images/svg/close_eye.svg';

const LessonList = ({ lessons, topicId, showArchived, handlePopup, token, onUpdate }) => {
	let filteredLessons = lessons
		.filter((lesson) => showArchived ? true : lesson.general.lessonActive === '1')
		.filter((lesson) => lesson.general.lessonTopic === topicId);

	const handleArchive = (status, lessonId) => {
		const dataForm = new FormData();
		dataForm.append('lessonId', lessonId);
		dataForm.append('newStatus', status);

		axiosPost(lessonarchive_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				onUpdate();
			}
		});
	};

	return (
		<li class="periodContainer flexRow active">
			<div className="PeriodWrap">
				{filteredLessons.map((lesson, index) => {
					const {
						general: { lessonName, lessonActive }
					} = lesson;
					return (
						<div
							key={`lesson_${index}`}
							className={`lesson ${
								lessonActive == 0 ? 'archived' : ''
							}`}
						>
							<span>{lessonName}</span>
							{lesson.editable == true ? (
								<button
									className="toggleBtn"
									onClick={() => handlePopup('edit', null, null, lesson)}
								>
									<Edit />
								</button>
							) : (
								<button
									className="toggleBtn"
									onClick={() => handleArchive(lessonActive == 0 ? 'unarchive' : 'archive', lesson.lessonId)}
								>
									{lessonActive == 0 ? <EyeClosed /> : <Eye />}
								</button>
							)}
						</div>
					);
				})}
			</div>
		</li>
	);
};

const ManageLessons = ({ match, topics }) => {
	const {
		initialData: { token }
	} = useSelector(state => state);

	const [data, setData] = useState(null);
	const [lessons, setLessons] = useState(null);
	const [goals, setGoals] = useState(null);
	const [loading, setLoading] = useState(true);
	const [showArchived, setShowArchived] = useState(false);
	const [openPopup, setOpenPopup] = useState();
	const [popupdata, setPopupData] = useState();

	useEffect(() => {
		getData();
		return () => {};
	}, []);

	const getData = (loader = true) => {
		if (loader) {
			setLoading(true);
		}

		const dataForm = new FormData();
		dataForm.append('topicId', getCurrentTopic(topics, match.params.topicname).topicId);

		axiosPost(lessonlist_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				setLoading(false);
				setGoals(axiosResult.topicsgoals);
				setData(axiosResult.seasonperiods);
				setLessons(axiosResult.lessons);
				console.log('axiosResult', axiosResult);
			} else {
				setLoading(false);
				console.log('axiosResult', axiosResult);
			}
		});
	};

	const reOrderPeriod = (sId, pIds) => {
		const dataForm = new FormData();
		dataForm.append('periodIds', pIds);
		dataForm.append('seasonId', sId);

		axiosPost(periodreorder_endpoint, dataForm, token, axiosResult => {
			if (axiosResult.status === 'success') {
				getData(false);
			} else {
				setLoading(false);
				console.log('axiosResult', axiosResult);
			}
		});
	};

	const handlePopup = (popupType, seasonId, periodId, lesson = null) => {
		setOpenPopup(true);
		// console.log('popupType: ',popupType,'seasonId: ',seasonId,'periodId: ',periodId,'lesson: ',lesson);
		setPopupData({
			popupType: popupType,
			topicId: lesson?.general?.lessonTopic,
			seasonId: seasonId,
			periodId: periodId,
			lesson: lesson
		});
	};

	const closePopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					setOpenPopup(false);
				}, 200);
			}
		});
	};

	useEffect(() => {
		if (openPopup) {
			TweenMax.to('.popupWrapper', 0.3, {
				autoAlpha: 1
			});
		}
	}, [openPopup]);

	return (
		<>
			<div className="manageLessonsWrapper">
				<ul className="tabsStyle">
					{topics.map((item, i) => (
						<li
							key={i}
							className={`${item.topicName
								.replace(/ /g, '-')
								.toLowerCase()}_tab`}
						>
							<NavLink
								to={`/curriculum/managelessons/${item.topicName
									.replace(/ /g, '-')
									.toLowerCase()}`}
							>
								{item.topicName}
							</NavLink>
						</li>
					))}
				</ul>
				<div
					className={`tabBorderStyle ${
						match.params.topicname
							? match.params.topicname.replace(/ /g, '-').concat('_wrapper')
							: null
					}`}
				>
					<div className="pageContainer">
						<div
							className={`groupResultsWrapper ${loading ? 'flexCenter' : ''}`}
						>
							{loading ? (
								<Loader />
							) : (
								<div>
									<h1 className="title">Beheer Lessen</h1>
									<p className="pageintro">
										Hier beheer je de lessen die je wilt observeren met de
										bijbehorende lesmaterialen. Een les past in één van de
										thema's die jullie aanbieden.
									</p>
									<button
										type="button"
										onClick={() => {
											handlePopup('add');
										}}
										className={`outlineButton ${ showArchived ? 'disableButton' : 'doneButton' }`}
										style={{ marginRight: 8 }}
									>
										Nieuw les
									</button>
									<button
										type="button"
										onClick={() => setShowArchived(!showArchived)}
										className={`outlineButton ${ showArchived ? 'disableButton' : 'doneButton' }`}
									>
										{showArchived ? 'Verberg gearchiveerd' : 'Zie gearchiveerd'}
									</button>
									<br/>
									<br/>
									<ul className="themeList">
										{lessons &&
											<LessonList
												lessons={lessons}
												topicId={getCurrentTopic(topics, match.params.topicname).topicId}
												showArchived={showArchived}
												handlePopup={handlePopup}
												token={token}
												onUpdate={() => getData(false)}
											/>
										}
									</ul>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{openPopup && (
				<LessonAddEditPopup
					data={data}
					token={token}
					goals={goals}
					popupdata={popupdata}
					hidepopup={closePopup}
					updateData={getData}
					name={popupdata.popupType}
				/>
			)}
		</>
	);
};

const mapStateToProps = state => ({
    topics: state.initialData.topics,
});

export default connect(mapStateToProps, null)(ManageLessons)
