// @import packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { TweenLite } from 'gsap/TweenMax';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
// @import actions
import { setGroupId, setStudentList } from 'actions/actions';
import { setDefaultStudent } from 'actions/parentsAction';
// @import components
import Student from 'js/components/student';
import ErrorPopup from 'js/components/popup/ErrorPopup';
import ChapterProgress from 'js/containers/academie/chapterProgress';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { grouptoSlug } from 'js/utils/groupUtility';
// @import placeholders
import TextLinePlaceholder from './placeholder/textLine';
import ListItemPlaceholder from './placeholder/ListItem';
// @import styles
import 'css/components/rightbar.scss';
// @import images
import Loader from 'images/loader.gif';
import avatar from 'images/avatar.png';
// @import constants
import { themeConfig } from 'themeConfig';
import { app_name, downloadreport_endpoint } from 'js/constants/endpoints';
// @import svg
import { ReactComponent as More } from 'images/svg/more.svg';
import { ReactComponent as Arrow } from 'images/svg/up-arrow.svg';

class Rightbar extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			downloadError: '',
			downloadRequested: null
		};
	}

	componentDidMount() {
		if (this.props.groupList && this.props.userData.userRole === 'teacher') {
			const getGroup = this.props.groupList.groups.find(
				x => x.groupName === this.props.groupName
			);
			this.props.setStudentList(
				getGroup.groupId,
				getGroup.groupName,
				getGroup.students
			);
			this.setState({
				groupId: getGroup.groupId,
				groupName: getGroup.groupName
			});
		}
		if (this.props.studentList) {
			this.setState({
				isLoading: false
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.studentList !== prevProps.studentList) {
			this.setState({
				isLoading: false
			});
		}
	}

	handleToggle = event => {
		const button = event.currentTarget;
		const collapsableWrapper = button.parentElement.nextElementSibling;
		if (collapsableWrapper.classList.contains('closed')) {
			button.classList.add('active');
			TweenLite.set(collapsableWrapper, { height: 'auto' });
			TweenLite.from(collapsableWrapper, 0.2, {
				height: 0,
				paddingTop: 0,
				onComplete: () => {
					collapsableWrapper.classList.remove('closed');
					collapsableWrapper.classList.add('opened');
				}
			});
		} else {
			button.classList.remove('active');
			TweenLite.to(collapsableWrapper, 0.2, {
				height: 0,
				paddingTop: 0,
				onComplete: () => {
					collapsableWrapper.classList.remove('opened');
					collapsableWrapper.classList.add('closed');
				}
			});
		}
	};

	handleLogOut = () => {
		const { cookies } = this.props;
		let expDate = new Date();
		cookies.remove('Authtoken', {
			domain: window.location.hostname.includes('onderbouwd')
				? 'onderbouwdonline.nl'
				: 'kleuterwijzer.nl',
			expires: expDate,
			path: '/'
		});
		window.location.href = this.props.signoutUrl;
	};

	handleGroupChange = event => {
		const groupName = event.target.options[event.target.selectedIndex].text;
		const groupId = event.target.value;
		this.setState(
			{
				groupId,
				groupName,
				isLoading: true
			},
			() => {
				const getGroup = this.props.groupList.groups.find(
					x => x.groupId === groupId
				);
				this.props.setStudentList(groupId, groupName, getGroup.students);
				this.props.setGroupId(groupId, groupName);
				if (
					this.props.location.pathname !== this.props.defaultUrl &&
					!this.props.location.pathname.includes('lessen')
				) {
					this.props.history.push(this.props.defaultUrl);
				}
			}
		);
	};

	downloadReport = studentreportId => {
		const dataForm = new FormData();
		dataForm.append('studentreportId', studentreportId);

		this.setState({
			downloadRequested: studentreportId
		});

		axiosPost(
			downloadreport_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status == 'success') {
					const link = `data:application/pdf;base64,${axiosResult.download.filedata}`;
					var a = document.createElement('a');
					a.setAttribute('download', axiosResult.download.filename);
					a.setAttribute('href', link);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				} else {
					this.setState({
						downloadError: axiosResult.failreason
					});
				}
				this.setState({
					downloadRequested: null
				});
			}
		);
	};

	render() {
		let groups = this.props.groupList?.groups;
		return (
			<div className="rightBar">
				<Link to={this.props.defaultUrl} className="logoColumn">
					<img
						src={
							this.props.userData.userRole === 'parent'
								? themeConfig[app_name].parentLogo
								: themeConfig[app_name].logo
						}
						alt={themeConfig[app_name].name}
						className="logo"
					/>
				</Link>
				{this.props.userData ? (
					<div className="schoolname">
						{this.props.userData.schoolDisplayname}
					</div>
				) : (
					<div
						style={{
							height: '30px',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<TextLinePlaceholder width="230px" height="10px" />
					</div>
				)}
				{this.props.userData ? (
					<div className="userOptions">
						<div className="user">
							<div>
								{this.props.userData.userPicture ? (
									<img
										src={this.props.userData.userPicture.replace(
											/(\.[^.]+)$/,
											'-40x40$1'
										)}
										srcSet={`${this.props.userData.userPicture.replace(
											/(\.[^.]+)$/,
											'-80x80$1'
										)} 2x`}
										alt={this.props.userData.userDisplayname}
									/>
								) : (
									<img src={avatar} alt={this.props.userData.userDisplayname} />
								)}
								<span>
									{this.props.userData.userDisplayname !== ' '
										? this.props.userData.userDisplayname
										: this.props.userData.userRole === 'teacher'
										? 'Leerkracht'
										: 'IB-er'}
								</span>
							</div>
							<button type="button" onClick={this.handleToggle}>
								<More />
							</button>
						</div>
						<ul className="optionList closed">
							<li>
								<NavLink to="/mijn-gegevens" activeClassName="active" exact>
									Mijn gegevens
								</NavLink>
							</li>
							<li>
								<NavLink to="/mijn-wachtwoord" activeClassName="active" exact>
									Mijn wachtwoord
								</NavLink>
							</li>
							{this.props.userData.userRole === 'teacher' &&
								app_name === 'onderbouwdonline' && (
									<li>
										<NavLink to="/mijn-aankopen" activeClassName="active" exact>
											Mijn aankopen
										</NavLink>
									</li>
								)}
							<li>
								<button type="button" onClick={this.handleLogOut}>
									Uitloggen
								</button>
							</li>
						</ul>
					</div>
				) : (
					<TextLinePlaceholder
						margin="0 0 0 15px"
						height="30px"
						width="130px"
					/>
				)}

				{this.props.userData &&
					this.props.userData.userRole === 'teacher' &&
					!this.props.location.pathname.includes('academie') && (
						<div className="groupdropdownWrapper">
							{groups ? (
								<div className="row">
									{groups?.length === 1 ? (
										<span className="groupDropDown">{groups[0].groupName}</span>
									) : (
										<>
											<select
												className="groupDropDown"
												onChange={this.handleGroupChange}
												value={this.props.groupId}
											>
												{groups?.map((item, i) => (
													<option key={i} value={item.groupId}>
														{item.groupName}
													</option>
												))}
											</select>
											<Arrow className="arrowIcon" />
										</>
									)}
									<button type="button" onClick={this.handleToggle}>
										<More />
									</button>
								</div>
							) : (
								<TextLinePlaceholder
									margin="0 0 0 15px"
									height="30px"
									width="110px"
								/>
							)}
							<ul className="groupLinks closed">
								<li>
									<NavLink
										to={`/groep/${grouptoSlug(
											this.props.groupName
										)}/leerlingen`}
										activeClassName="active"
										exact
									>
										Beheer leerlingen
									</NavLink>
								</li>
								{this.props.schoolParentsEnabled === '1' && (
									<li>
										<NavLink
											to={`/groep/${grouptoSlug(this.props.groupName)}/ouders`}
											activeClassName="active"
											exact
										>
											Beheer ouders
										</NavLink>
									</li>
								)}
								{this.props.studentList && (
									<li>
										<NavLink
											to={`/groep/${grouptoSlug(
												this.props.groupName
											)}/leerlingenkaart`}
											activeClassName="active"
											exact
										>
											Leerlingenkaart
										</NavLink>
									</li>
								)}
								<li>
									<NavLink
										to={`/groep/${grouptoSlug(this.props.groupName)}/media`}
										activeClassName="active"
										exact
									>
										Foto's en video's
									</NavLink>
								</li>
							</ul>
						</div>
					)}

				{!this.state.isLoading
					? this.props.studentList &&
					  !this.props.location.pathname.includes('academie') && (
							<div className="studentList">
								{this.props.studentList !== 'nodata' ? (
									this.props.studentList.map((item, i) => {
										const studentName = item.studentDisplayname
											.replace(/ /g, '-')
											.toLowerCase();
										let ismatch = false;
										if (this.props.location.pathname.includes(studentName)) {
											ismatch = true;
										}
										return (
											<NavLink
												to={`/leerling/${grouptoSlug(
													this.props.groupName
												)}/${item.studentDisplayname
													.replace(/ /g, '-')
													.toLowerCase()}/resultaten`}
												activeClassName="activeStudent"
												className={`${ismatch ? 'activeStudent' : ''}`}
												key={i}
												exact
											>
												<Student
													studentId={item.studentId}
													name={item.studentDisplayname}
													imagepath={item.studentPicture}
													size={40}
													key={i}
												/>
											</NavLink>
										);
									})
								) : (
									<Fragment />
								)}
							</div>
					  )
					: this.props.userData.userRole === 'teacher' &&
					  !this.props.location.pathname.includes('academie') && (
							<div className="studentList">
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
								<ListItemPlaceholder />
							</div>
					  )}

				{this.props.coursehistory &&
					this.props.coursehistory.length > 0 &&
					this.props.location.pathname.includes('academie') &&
					this.props.coursehistory.map(course => {
						return (
							<ChapterProgress key={`id_${course.acourseId}`} course={course} />
						);
					})}

				{this.props.childrenlist &&
				this.props.userData.userRole === 'parent' ? (
					<div className="studentList">
						{this.props.childrenlist.map((item, i) => {
							const studentName = item.studentDisplayname
								.replace(/ /g, '-')
								.toLowerCase();
							let ismatch = false;
							if (this.props.location.pathname.includes(studentName)) {
								ismatch = true;
							}
							return (
								<div
									className={`accordian ${ismatch ? 'expand' : ''}`}
									key={`std_${i}`}
								>
									<NavLink
										to={`/${item.studentDisplayname
											.replace(/ /g, '-')
											.toLowerCase()}/${this.props.defaultUrl.split('/')[2]}/${
											this.props.defaultUrl.split('/')[3]
										}`}
										onClick={e => {
											this.props.setDefaultStudent(
												item.studentDisplayname.replace(/ /g, '-').toLowerCase()
											);
											if (ismatch) {
												e.preventDefault();
											}
										}}
										className="studentParnt"
										exact
									>
										<Student
											size={40}
											studentId={item.studentId}
											name={item.studentDisplayname}
											imagepath={item.studentPicture}
											groupdetails={item.group.groupdetails}
										/>
									</NavLink>

									{this.props.userSettings.schoolParentsEnabled === '1' &&
										item.media.length > 0 && (
											<div className="stdReportsWrapp">
												<h4>Foto's en video's</h4>
												<Link
													to={`/${item.studentDisplayname
														.replace(/ /g, '-')
														.toLowerCase()}/groep/${grouptoSlug(
														item.group.groupdetails.groupName
													)}/media`}
													className="flexBlock flexSpaceCenter photoWrapper"
												>
													{item.media.map(media => {
														const iframeUrl = media.mediaHash
															? `https://player.vimeo.com/video/${media.mediaExternalVideoId}?h=${media.mediaHash}&background=1&muted=1&autoplay=0`
															: `https://player.vimeo.com/video/${media.mediaExternalVideoId}?&background=1&muted=1&autoplay=0`;
														return (
															<div key={media.mediaId} className="flexCenter">
																<div className="aspectContainer">
																	{media.mediaType === 'video' ? (
																		<iframe
																			frameBorder="0"
																			src={iframeUrl}
																			allow="autoplay; fullscreen; picture-in-picture"
																		/>
																	) : (
																		<img
																			src={media.mediaFile}
																			alt={media.mediaTitle}
																		/>
																	)}
																</div>
															</div>
														);
													})}
												</Link>
											</div>
										)}

									{item.group.studentreports.length > 0 && (
										<div className="stdReportsWrapp">
											<h4>Leerlingportfolio</h4>
											{item.group.studentreports.map(report => {
												return (
													<Fragment key={`report${report.studentreportId}`}>
														{this.state.downloadRequested ==
														report.studentreportId ? (
															<img
																alt=""
																width={30}
																src={Loader}
																className="reportLoading"
																style={{ marginTop: '15px' }}
															/>
														) : (
															<a
																target="_blank"
																className="stdReport"
																rel="noopener noreferrer"
																onClick={() =>
																	this.downloadReport(
																		report.studentreportId,
																		report.studentreportTitle
																	)
																}
															>
																{report.studentreportTitle}
															</a>
														)}
													</Fragment>
												);
											})}
										</div>
									)}

									{item.group.teachers.length > 0 && (
										<div className="teacherList">
											<h4>Leerkrachten</h4>
											{item.group.teachers.map(teacher => (
												<div
													key={`teacher${teacher.userId}`}
													className="teacher"
												>
													<img
														src={teacher.userPicture.replace(
															/(\.[^.]+)$/,
															`-${60}x${60}$1`
														)}
														srcSet={`${teacher.userPicture.replace(
															/(\.[^.]+)$/,
															`-${60 * 2}x${60 * 2}$1`
														)} 2x`}
														alt={teacher.userDisplayname}
													/>
													<span>{teacher.userDisplayname}</span>
												</div>
											))}
										</div>
									)}
								</div>
							);
						})}
					</div>
				) : (
					this.props.userData.userRole === 'parent' && (
						<div className="studentList">
							<ListItemPlaceholder />
							<ListItemPlaceholder />
							<ListItemPlaceholder />
						</div>
					)
				)}
				{this.state.downloadError && (
					<ErrorPopup
						content={this.state.downloadError}
						closeCallback={() => this.setState({ downloadError: '' })}
					/>
				)}
			</div>
		);
	}
}

const mapStateToprops = state => {
	return {
		token: state.initialData.token,
		topicId: state.initialData.topicId,
		groupId: state.initialData.groupId,
		userData: state.initialData.userData,
		groupList: state.initialData.groupList,
		groupName: state.initialData.groupName,
		defaultUrl: state.initialData.defaultUrl,
		signoutUrl: state.initialData.signoutUrl,
		childrenlist: state.parents.childrenlist,
		coursehistory: state.academy.coursehistory,
		studentList: state.initialData.studentList,
		userSettings: state.initialData.genericSettings,
		schoolParentsEnabled: state.initialData.schoolParentsEnabled
	};
};

export default withRouter(
	withCookies(
		connect(mapStateToprops, { setGroupId, setStudentList, setDefaultStudent })(
			Rightbar
		)
	)
);
