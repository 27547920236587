// @import packages
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TweenMax } from 'gsap/TweenMax';
import { connect } from 'react-redux';
import Masonry from 'react-responsive-masonry';
import ReactToPrint from 'react-to-print';
// @import actions
import { sendErrorToHOC } from 'actions/actions';
// @import constants
import { groupbehandelplan_endpoint } from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
import Student from 'js/components/student';
import ObservationPopup from 'js/models/observationPopup';
import BehandelplanTabs from './components/behandelplanTabs';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { grouptoSlug, slugtoGroup } from 'js/utils/groupUtility';
import { getCurrentGroup, getCurrentPeriod } from 'js/utils/filterArray';
// @import images/svgs
import { ReactComponent as PrintIcon } from 'images/svg/printer.svg';
// @import styles
import './index.scss';

const Behandelplan = props => {
	let { groupname, treatmenttype, season, period } = useParams();
	groupname = slugtoGroup(groupname);

	const [isLoading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [popupObject, setPopupObject] = useState(null);

	const componentRef = useRef(null);
	const masonryRef = useRef(null);

	useEffect(() => {
		getBehandelplan();
		return () => {};
	}, []);

	const getBehandelplan = () => {
		setLoading(true);
		const groupId = getCurrentGroup(props.groupList.groups, groupname).groupId;
		const dataForm = new FormData();
		dataForm.append('groupId', groupId);
		dataForm.append(
			'treatmentType',
			treatmenttype === 'in-ontwikkeling' ? 'behind' : 'ahead'
		);
		if (season && period) {
			const currentPeriod = getCurrentPeriod(props.lessonTree, season, period);
			dataForm.append('periodId', currentPeriod.periodId);
		}

		axiosPost(
			groupbehandelplan_endpoint,
			dataForm,
			props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					const data = axiosResult.data.results.filter(x => {
						const array = x.steps.filter(y => y.students.length > 0);
						if (array.length > 0) return x;
					});
					setData(data);
				}
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		if (popupObject) {
			TweenMax.to('.popupWrapper', 0.3, {
				autoAlpha: 1
			});
		}
	}, [popupObject]);

	const showObservationPopup = (topicId, step, lesson, student) => {
		let message = lesson.stepdescriptions[step].replace(
			/#kind/gi,
			student.studentFirstname
		);

		const object = {
			step,
			lesson,
			student,
			message,
			topicId
		};

		setPopupObject(object);
	};

	const hideObservationPopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setPopupObject(null);
			}
		});
	};

	return (
		<div className="mainContainer">
			<div className="flexBlock flexSpaceCenter">
				<ul className="tabsStyle">
					{props.topics && props.topics.map((topic, i) => {
						let path = '';
						if (props.userData.userRole === 'teacher') {
							path = `/groep/${grouptoSlug(groupname)}/groepsoverzicht${
								season && period ? `/${season}/${period}` : ''
							}`;
						} else {
							path = `/groepsoverzichten/${grouptoSlug(
								groupname
							)}/${topic.topicName.replace(/ /g, '-').toLowerCase()}`;
						}
						return (
							<li
								key={i}
								className={`${topic.topicName
									.replace(/ /g, '-')
									.toLowerCase()}_tab`}
							>
								<Link
									to={{
										pathname: path,
										state: {
											topic: topic
										}
									}}
								>
									{topic.topicName}
								</Link>
							</li>
						);
					})}

					<BehandelplanTabs
						season={season}
						period={period}
						groupName={groupname}
					/>
				</ul>

				{season && period && (
					<Link
						className="groupLink"
						to={`/groep/${grouptoSlug(groupname)}/groepsoverzicht`}
					>
						Volledig Groepsoverzicht
					</Link>
				)}
			</div>
			<div
				ref={componentRef}
				className={`tabBorderStyle ${
					treatmenttype === 'in-ontwikkeling' ? 'behind' : 'ahead'
				}_wrapper`}
			>
				<div className="pageContainer">
					<div
						className={`groupResultsWrapper ${isLoading ? 'flexCenter' : ''}`}
					>
						{isLoading ? (
							<Loader />
						) : (
							<>
								<h1 className="headingStyle titleCase">{`${groupname} - ${treatmenttype.replace(
									/-/g,
									' '
								)} Behandelplan ${period ? `- ${period}` : ''}`}</h1>

								<ReactToPrint
									trigger={() => (
										<button type="button" className="printButton">
											<PrintIcon />
										</button>
									)}
									pageStyle={
										'@media print{@page{size: landscape; margin: 5mm 7mm 5mm 7mm;} body{margin:0;} .pageContainer{background: white !important;} .printButton{display: none;}}'
									}
									content={() => componentRef.current}
								/>

								<div ref={masonryRef} className="behandelplanContainer">
									{data && data.length > 0 && (
										<Masonry>
											{data.map((lesson, i) => {
												const {
													topicId,
													steps,
													lessonName,
													lessonPicture,
													lessonIntroduction
												} = lesson;
												return (
													<div
														id={`lessonId${i}`}
														key={`lessonId${i}`}
														className="column"
													>
														<div
															className={`${props.topics && props.topics
																.find(x => x.topicId === topicId)
																.topicName.replace(/ /g, '-')
																.toLowerCase()} lessonWrap`}
														>
															<section>
																<div className="lessonRow">
																	<img
																		src={lessonPicture.replace(
																			/(\.[^.]+)$/,
																			`-60x90$1`
																		)}
																		srcSet={`${lessonPicture.replace(
																			/(\.[^.]+)$/,
																			`-120x180$1`
																		)} 2x`}
																		alt=""
																	/>
																	<div className="lessonColumn">
																		<h3>{lessonName}</h3>
																		<p>{lessonIntroduction}</p>
																	</div>
																</div>
															</section>
															{steps.map((step, ind) => {
																if (step.students.length < 1) return;
																return (
																	<div
																		key={`stepcontainer_${ind}`}
																		className="stepContainer"
																	>
																		<div className="stepWrapper flexBlock">
																			<span className="studentStep">
																				{step.step === 'E' ? (
																					<b>•</b>
																				) : (
																					step.step
																				)}
																			</span>
																			<span className="studentStepDescr">
																				{step.stepdescription}
																			</span>
																		</div>
																		{step.students &&
																			step.students.map((student, i) => {
																				return (
																					<button
																						type="button"
																						key={`studentkey_${i}`}
																						className="studentRowContainer"
																						onClick={() => {
																							showObservationPopup(
																								topicId,
																								step.step,
																								lesson,
																								student
																							);
																						}}
																					>
																						<Student
																							size={80}
																							age={student.studentDisplayage}
																							name={student.studentDisplayname}
																							imagepath={student.studentPicture}
																						/>
																						{student.latestcomments && (
																							<p className="latestComments">
																								{student.latestcomments}
																							</p>
																						)}
																					</button>
																				);
																			})}
																	</div>
																);
															})}
														</div>
													</div>
												);
											})}
										</Masonry>
									)}
									{data && data.length === 0 && (
										<p className="nodata">
											{treatmenttype === 'in-ontwikkeling'
												? `Er zijn geen leerlingen In Ontwikkeling ${
														period ? `in '${period}'` : ''
												  }`
												: `Er zijn geen leerlingen met een leervoorsprong ${
														period ? `in '${period}'` : ''
												  }`}
										</p>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			{popupObject && (
				<ObservationPopup
					readOnly={props.userData.userRole !== 'teacher'}
					draggedStudent={popupObject.student}
					studentPerformance={
						treatmenttype === 'in-ontwikkeling' ? 'behind' : 'ahead'
					}
					stepFrom={popupObject.step}
					stepTo={popupObject.step}
					message={popupObject.message}
					lessonId={popupObject.lesson.lessonId}
					clickedLesson={popupObject.lesson}
					hidepopup={hideObservationPopup}
					callback={getBehandelplan}
					topicName={
						props.topics.find(x => x.topicId === popupObject.topicId).topicName
					}
				/>
			)}
		</div>
	);
};

const mapStateToProps = state => ({
	token: state.initialData.token,
	topics: state.initialData.topics,
	userData: state.initialData.userData,
	groupList: state.initialData.groupList,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, {
	sendErrorToHOC
})(Behandelplan);
