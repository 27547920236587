// @import packages
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { TweenMax } from 'gsap/TweenMax';
// @import actions
import { sendErrorToHOC } from 'actions/actions';
// @import constants
import {
	groupresults_endpoint,
	getstudentperformance_endpoint
} from 'js/constants/endpoints';
// @import components
import Loader from 'js/models/loader';
import Student from 'js/components/student';
import ObservationPopup from 'js/models/observationPopup';
// @import styles
import 'css/containers/groupResults.scss';
// @import images
import { ReactComponent as Pencil } from 'images/svg/edit.svg';
import { ReactComponent as PrintIcon } from 'images/svg/printer.svg';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { grouptoSlug, slugtoGroup } from 'js/utils/groupUtility';
import { getCurrentGroup, getCurrentTopic } from 'js/utils/filterArray';

export class GroupResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			groupName: null,
			topicName: null
		};
	}

	componentDidMount() {
		this.setInitialStates();
	}

	setInitialStates = () => {
		let { groupname, topicname } = this.props.match.params;
		groupname = slugtoGroup(groupname);
		const group = getCurrentGroup(
			this.props.initialData.groupList.groups,
			groupname
		);
		this.setState(
			{
				groupId: group.groupId,
				groupName: group.groupName,
				topicName: topicname
			},
			() => this.getGroupResults()
		);
	};

	getGroupResults = () => {
		this.setState({
			isLoading: true,
			lessons: null
		});

		const dataForm = new FormData();
		dataForm.append('groupId', this.state.groupId);
		dataForm.append('topicId', getCurrentTopic(this.props.topics, this.state.topicName).topicId);

		axiosPost(
			groupresults_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						isLoading: false,
						headers: axiosResult.data.headers,
						students: axiosResult.data.students
					});
				}
			}
		);
	};

	handleGroupChange = event => {
		event.preventDefault();
		const groupName = event.target.options[event.target.selectedIndex].text;

		this.props.history.push(
			`/groepsoverzichten/${grouptoSlug(groupName)}/${this.state.topicName}`
		);
	};

	handlePopup = (step, lesson, student, studentPerformance) => {
		let message = lesson.stepdescriptions.find(
			item => item.step === step
		).description;
		message = message.replace(/#kind/gi, student.studentFirstname);

		const dataForm = new FormData();
		dataForm.append('studentId', student.studentId);
		dataForm.append('newStep', step);

		axiosPost(
			getstudentperformance_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						draggedStudentPerformance: axiosResult.data.studentPerformance
					});
				}
			}
		);

		this.setState(
			{
				draggedStudent: student,
				currentStep: step,
				draggedMessage: message,
				draggedLessonId: lesson.lessonId,
				studentPerformance,
				clickedLesson: lesson,
				showStudentPopup: true
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	hideStudentPopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						showStudentPopup: false
					});
				}, 200);
			}
		});
	};

	render() {
		return (
			<div className="mainContainer">
				{this.state.groupName && (
					<ul className="tabsStyle">
						{this.props.topics && this.props.topics.map((item, i) => (
							<li
								key={i}
								className={`${item.topicName
									.replace(/ /g, '-')
									.toLowerCase()}_tab`}
							>
								<NavLink
									to={`/groepsoverzichten/${grouptoSlug(
										this.state.groupName
									)}/${item.topicName.replace(/ /g, '-').toLowerCase()}`}
								>
									{item.topicName}
								</NavLink>
							</li>
						))}

						<li className="behind_tab">
							<NavLink
								to={`/groepsoverzicht/${grouptoSlug(
									this.state.groupName
								)}/behandelplan/in-ontwikkeling`}
							>
								In ontwikkeling
							</NavLink>
						</li>
						<li className="ahead_tab">
							<NavLink
								to={`/groepsoverzicht/${grouptoSlug(
									this.state.groupName
								)}/behandelplan/leervoorsprong`}
							>
								Leervoorsprong
							</NavLink>
						</li>
					</ul>
				)}

				<div
					ref={el => (this.componentRef = el)}
					className={`tabBorderStyle ${
						this.state.topicName
							? this.state.topicName
									.replace(/ /g, '-')
									.toLowerCase()
									.concat('_wrapper')
							: null
					}`}
				>
					<div className="pageContainer">
						<div
							className={`groupResultsWrapper ${
								this.state.isLoading ? 'flexCenter' : ''
							}`}
						>
							{this.state.isLoading ? (
								<Loader />
							) : (
								<>
									{this.props.initialData.groupList && (
										<select
											value={this.state.groupId}
											onChange={this.handleGroupChange}
										>
											{this.props.initialData.groupList.groups.map(
												(item, i) => (
													<option key={i} value={item.groupId}>
														{item.groupName}
													</option>
												)
											)}
										</select>
									)}

									<h1>
										{`${this.state.groupName} - Groepsoverzicht ${this.state.topicName}`}
									</h1>

									<ReactToPrint
										trigger={() => (
											<button type="button" className="printButton">
												<PrintIcon />
											</button>
										)}
										pageStyle={
											'@media print{@page{size: landscape; margin: 5mm 7mm 5mm 7mm;} body{margin:0;} .pageContainer{background: white !important;} .printButton{display: none;}}'
										}
										content={() => this.componentRef}
									/>
									{!this.state.isLoading && (
										<div className="overflowController">
											{this.state.headers && (
												<div className="row headerRow">
													<div className="studentColumn" />
													<div className="tableColumn">
														{this.state.headers.map((item, i) => {
															return (
																<div
																	className="groupColumn"
																	key={i}
																	data-goalid={item.goalId}
																>
																	<div className="goalRow">
																		<h2 className="goalName">
																			{item.goalName}
																		</h2>
																		<img
																			src={item.goalImage.replace(
																				/(\.[^.]+)$/,
																				'-80x80$1'
																			)}
																			srcSet={`${item.goalImage.replace(
																				/(\.[^.]+)$/,
																				'-160x160$1'
																			)} 2x`}
																			alt={item.goalName}
																		/>
																	</div>
																	<div className="row">
																		{item.lessons.map((res, i) => (
																			<div className="step" key={i}>
																				<span
																					className="lessonName"
																					data-lessonid={res.lessonId}
																				>
																					{res.lessonName}
																				</span>
																			</div>
																		))}
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											)}

											{this.state.students &&
												this.state.students.map(item => {
													return (
														<div className="row" key={item.studentId}>
															<div className="studentColumn">
																<Student
																	studentId={item.studentId}
																	name={item.studentDisplayname}
																	imagepath={item.studentPicture}
																	size={40}
																/>
															</div>
															<div className="tableColumn">
																{this.state.headers?.map((headerItem, i) => (
																	<div className="groupColumn" key={i}>
																		<div className="row">
																			{headerItem.lessons.map((res, i) => {
																				let currentStep;
																				let eyestatus;
																				let currentPerformance;
																				let studentNotification;
																				if (item.scores) {
																					const results = item.scores.find(
																						x => x.lessonId === res.lessonId
																					);
																					if (results) {
																						currentStep = results.step;
																						eyestatus = results.eyestatus;
																						currentPerformance =
																							results.studentPerformance;
																						studentNotification =
																							results.studentNotification;
																					}
																				}
																				return (
																					<button
																						type="button"
																						className={`step ${
																							currentPerformance
																								? currentPerformance.replace(
																										/ /g,
																										'-'
																								  )
																								: ''
																						} ${currentStep ? 'cursor' : ''}`}
																						key={res.lessonId}
																						onClick={
																							currentPerformance
																								? () =>
																										this.handlePopup(
																											currentStep,
																											res,
																											item,
																											currentPerformance
																										)
																								: e =>
																										e.preventDefault(
																											currentStep
																										)
																						}
																					>
																						<span className="currentStep">
																							{currentStep &&
																							currentStep === 'E' ? (
																								<b>•</b>
																							) : (
																								currentStep
																							)}
																							{studentNotification ===
																								'commented' && (
																								<div className="iconWrap">
																									<Pencil />
																								</div>
																							)}
																						</span>
																					</button>
																				);
																			})}
																		</div>
																	</div>
																))}
															</div>
														</div>
													);
												})}
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>

				{this.state.showStudentPopup && (
					<ObservationPopup
						readOnly
						draggedStudent={this.state.draggedStudent}
						studentPerformance={this.state.studentPerformance}
						stepFrom={this.state.currentStep}
						stepTo={this.state.currentStep}
						message={this.state.draggedMessage}
						lessonId={this.state.draggedLessonId}
						hidepopup={this.hideStudentPopup}
						clickedLesson={this.state.clickedLesson}
						topicName={this.state.topicName}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	topics: state.initialData.topics,
	initialData: state.initialData
});

export default connect(mapStateToProps, {
	sendErrorToHOC
})(GroupResults);
