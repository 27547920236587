import { slugtoGroup } from 'js/utils/groupUtility';

export const getCurrentGroup = (groups, groupname) => {
	return groups.find(x => slugtoGroup(x.groupName).toLowerCase() === groupname);
};

export const getCurrentPeriod = (lessonTree, seasonName, periodName) => {
	const currentSeason = lessonTree.find(
		x => x.seasonName.toLowerCase() === seasonName.toLowerCase()
	);

	let getPeriodFromURL = periodName;
	if (getPeriodFromURL.includes('-')) {
		getPeriodFromURL = getPeriodFromURL.replace(/-/g, ' ');
	}

	return currentSeason.periods.find(
		x => x.periodName.toLowerCase() === getPeriodFromURL.toLowerCase()
	);
};

export const getCurrentTopic = (topics, topic) => {
	return topics.find(
		x => x.topicName.replace(/ /g, '-').toLowerCase() === topic.replace(/ /g, '-')
	);
};
