// @import packages
import React, { Component, Fragment } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import { search_endpoint, app_name } from '../constants/endpoints';
// @import styles
import '../../css/components/header.scss';
// @import svg
import { ReactComponent as CrossIcon } from '../../images/svg/cross.svg';
import { ReactComponent as SearchIcon } from '../../images/svg/search.svg';
import { ReactComponent as CalendarIcon } from '../../images/svg/calendar.svg';
// @import utils
import { axiosPost } from '../utils/axiosCall';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSearchOpen: false,
			haveSearchResults: false,
			lessonResults: null,
			routeenLessonResults: null,
			studentResults: null,
			searchValue: ''
		};
		this.inputElement = null;
		this.searchTimeout = null;
		this.escFunction = this.escFunction.bind(this);
	}
	componentDidMount() {
		this.handleNewsNotify();
		document.addEventListener('keydown', this.escFunction, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}
	// handle escape press
	escFunction(event) {
		if (event.keyCode === 27) {
			this.handleSearchClose();
		}
	}
	// handle close function
	handleSearchClose = () => {
		if (this.state.isSearchOpen) {
			this.setState({
				isSearchOpen: false,
				searchValue: '',
				haveSearchResults: false,
				lessonResults: null,
				routeenLessonResults: null,
				studentResults: null
			});
			TweenMax.to('.searchWrapper', 0.3, {
				width: '30px'
			});
			TweenMax.to('.searchWrapper input', 0.3, {
				autoAlpha: 0
			});
		}
	};
	// handle search and close click
	handleSearchClick = () => {
		this.setState({
			isSearchOpen: !this.state.isSearchOpen
		});
		TweenMax.to('.searchWrapper', 0.3, {
			width: this.state.isSearchOpen ? '30px' : '100%'
		});
		TweenMax.to('.searchWrapper input', 0.3, {
			autoAlpha: this.state.isSearchOpen ? 0 : 1,
			onComplete: () => {
				if (this.state.isSearchOpen) {
					this.inputElement.focus();
				}
			}
		});
		if (this.state.isSearchOpen) {
			this.setState({
				searchValue: '',
				haveSearchResults: false,
				lessonResults: null,
				routeenLessonResults: null,
				studentResults: null
			});
		}
	};
	// handle search input change and fetch data from api endpoint search_endpoint
	handleInputChange = event => {
		clearTimeout(this.searchTimeout);
		const searchValue = event.target.value;
		this.setState({
			searchValue
		});
		this.searchTimeout = setTimeout(() => {
			this.setState({
				searchLoading: true
			});
			const dataForm = new FormData();
			dataForm.append('keyword', searchValue);

			axiosPost(search_endpoint, dataForm, this.props.token, axiosResult => {
				let lessonResults, routeenLessonResults, studentResults;
				if (axiosResult.status === 'success') {
					lessonResults = axiosResult.data.searchresult.filter(
						item => item.datatype === 'lesson'
					);
					routeenLessonResults = axiosResult.data.searchresult.filter(
						item => item.datatype === 'routinelesson'
					);
					studentResults = axiosResult.data.searchresult.filter(
						item => item.datatype === 'student'
					);
					this.setState({
						haveSearchResults: true,
						lessonResults,
						routeenLessonResults,
						studentResults,
						searchLoading: false
					});
				} else if (axiosResult.status === 'keyword too short') {
					this.setState({
						message: '......',
						searchLoading: false,
						haveSearchResults: false
					});
				} else if (axiosResult.status === 'no results') {
					this.setState({
						message: 'Geen resultaten',
						searchLoading: false,
						haveSearchResults: false
					});
				} else {
					this.setState({
						searchLoading: false,
						haveSearchResults: false
					});
				}
			});
		}, 20);
	};
	// handle news notify
	handleNewsNotify = () => {
		const notifyNews = localStorage.getItem('notify');
		if (notifyNews !== 'false') {
			const navMenu = document.querySelector('a[data-name="Nieuws"]');
			navMenu.classList.add('notify');
		}
	};
	render() {
		// console.log('students', this.state.studentResults);
		const { routes } = this.props;
		return (
			<header className="headerWrapper">
				<div className="navColumn">
					{this.props.lessonTree ? (
						<ul className="seasonTab">
							{/* {this.props.userData.userRole !== 'parent' && (
								<li className="kalendarTab">
									<NavLink
										activeClassName="activeLink"
										to="/kalender"
										style={{ padding: '0 14px' }}
									>
										<CalendarIcon
											width={25}
											fillOpacity={
												this.props.location.pathname.includes('kalender')
													? 1
													: 0.5
											}
										/>
									</NavLink>
								</li>
							)} */}
							{this.props.lessonTree.map((item, i) => {
								return (
									<li
										className={`${item.seasonName.toLowerCase()}_Season`}
										key={i}
									>
										{this.props.userData.userRole !== 'parent' && item.periods && item.periods.length ? (
											<Link
												to={`/lessen/${item.seasonName.toLowerCase()}/${item.periods[0].periodName
													.replace(/ /g, '-')
													.toLowerCase()}/${item.periods[0].topics[0].topicName.toLowerCase()}`}
											>
												{item.seasonName}
											</Link>
										) : item.periods && item.periods[0] && item.periods.length ? (
											<Link
												to={`/${
													this.props.defaultStudent
												}/${item.seasonName.toLowerCase()}/${item.periods[0].periodName.toLowerCase()}/`}
											>
												{item.seasonName}
											</Link>
										) : null}
									</li>
								);
							})}
						</ul>
					) : (
						<div />
					)}

					<nav className="navMenu">
						<div className="menu">
							{this.props.userData &&
								this.props.userData.userRole === 'teacher' && (
									<Fragment>
										{(app_name !== 'wijzneus') && (
												<NavLink
													to={`/groep/${this.props.groupName
														.replace(/ /g, '-')
														.replace(/\//g, '-')
														.toLowerCase()}/groepsoverzicht`}
													activeClassName="activeLink"
												>
													Groepsoverzicht
												</NavLink>
										)}
										<NavLink
											to={`/groep/${this.props.groupName
												.replace(/ /g, '-')
												.replace(/\//g, '-')
												.toLowerCase()}/jaarplanning`}
											activeClassName="activeLink"
											exact
										>
											Jaarplan
										</NavLink>
									</Fragment>
								)}
							{routes
								.filter(r => r.isInHeader)
								.filter(item => {
									if (item.routeFor) {
										if (item.routeFor.includes(app_name)) {
											return item;
										} else {
											return;
										}
									}
									return item;
								})
								.map((link, i) => {
									if (link.isExternal) {
										return (
											<a key={i} href={link.path} target={link.target}>
												{link.name}
											</a>
										);
									} else {
										let path = link.path;
										if (link.name === 'Beheer') {
											path = this.props.defaultUrl;
										}
										if (link.name === 'Resultaten') {
											path = `/resultaten/${this.props.topics[0].topicName.replace(/ /g, '-').toLowerCase()}`;
										}
										if (link.name === 'Groepsoverzichten') {
											if (
												this.props.groupList &&
												this.props.groupList.groups.length > 0
											) {
												path = `/groepsoverzichten/${this.props.groupList.groups[0].groupName
													.replace(/ /g, '-')
													.replace(/\//g, '-')
													.toLowerCase()}/${this.props.topics[0].topicName.replace(/ /g, '-').toLowerCase()}`;
											} else {
												return null;
											}
										}
										if (link.name === 'Academie') {
											if (this.props.schoolAcademyEnabled === '0') {
												return null;
											} else if (this.props.showintroduction === 0) {
												path = '/academie/opleiding';
											}
										}
										return (
											<NavLink
												to={path}
												data-name={link.name}
												className={`${
													this.props.location.pathname.includes(
														'groepsoverzichten'
													) && path.includes('groepsoverzichten')
														? 'activeLink'
														: ''
												}`}
												key={i}
												activeClassName="activeLink"
											>
												{link.name}
											</NavLink>
										);
									}
								})}
						</div>

						{this.props.userData.userRole !== 'parent' && (
							<div className="searchWrapper">
								<input
									placeholder="Zoek leerling, les, routineles"
									ref={input => (this.inputElement = input)}
									value={this.state.searchValue}
									onChange={this.handleInputChange}
								/>
								<button
									type="button"
									className="searchSubmit"
									onClick={this.handleSearchClick}
								>
									{this.state.isSearchOpen ? <CrossIcon /> : <SearchIcon />}
								</button>
								{this.state.searchValue.length > 0 && (
									<div className="searchDropdown">
										{this.state.haveSearchResults ? (
											<Fragment>
												{this.state.studentResults && (
													<div>
														<strong className="title">Leerlingen</strong>
														{this.state.studentResults.length > 0 ? (
															<ul>
																{this.state.studentResults.map(item => (
																	<li key={item.dataid}>
																		<Link
																			to={item.slug}
																			onClick={this.handleSearchClose}
																		>
																			{item.datarow}
																		</Link>
																	</li>
																))}
															</ul>
														) : (
															<p>Geen resultaten</p>
														)}
													</div>
												)}
												{this.state.lessonResults && (
													<div>
														<strong className="title">Lessen</strong>
														{this.state.lessonResults.length > 0 ? (
															<ul>
																{this.state.lessonResults.map(item => (
																	<li key={item.dataid}>
																		<Link
																			to={item.slug}
																			onClick={this.handleSearchClose}
																		>
																			{item.datarow}
																		</Link>
																	</li>
																))}
															</ul>
														) : (
															<p>Geen resultaten</p>
														)}
													</div>
												)}
												{this.state.routeenLessonResults && (
													<div>
														<strong className="title">Routinelessen</strong>
														{this.state.routeenLessonResults.length > 0 ? (
															<ul>
																{this.state.routeenLessonResults.map(item => (
																	<li key={item.dataid}>
																		<Link
																			to={item.slug}
																			onClick={this.handleSearchClose}
																		>
																			{item.datarow}
																		</Link>
																	</li>
																))}
															</ul>
														) : (
															<p>Geen resultaten</p>
														)}
													</div>
												)}
											</Fragment>
										) : (
											<p>{this.state.message}</p>
										)}
									</div>
								)}
							</div>
						)}
					</nav>
				</div>
			</header>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
    topics: state.initialData.topics,
	groupList: state.initialData.groupList,
	lessonTree: state.initialData.lessonTree,
	groupName: state.initialData.groupName,
	userData: state.initialData.userData,
	defaultUrl: state.initialData.defaultUrl,
	defaultStudent: state.parents.defaultStudent,
	showintroduction: state.initialData.showintroduction,
	schoolAcademyEnabled: state.initialData.schoolAcademyEnabled
});

export default withRouter(connect(mapStateToProps, null)(Header));
