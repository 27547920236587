// @import dependencies
import React, { PureComponent, Fragment } from 'react';
import { TweenMax } from 'gsap/TweenMax';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';

// @import constants
import {
	actionplan_endpoint,
	getshopitempurchase_endpoint,
	getstudentperformance_endpoint,
    app_name,
} from 'js/constants/endpoints';
// @import components
import LessonSection from 'js/components/lessonSection';
import LessonDetail from 'js/components/lessonDetail';
import RoutineLessonWrapper from 'js/components/routineLessonWrapper';
import PlaceHolderLoader from 'js/components/placeHolderLoader';
import ObservationPopup from 'js/models/observationPopup';
import YoutubeVideoPopup from 'js/components/popup/youtubeVideoPopup';
import IframePopup from 'js/components/popup/iframeLessonPopup';
import ProductWrapper from './components/ProductWrapper/index';
// @import actions
import {
	startObservation,
	changeLessonStep,
	fetchPeriodData,
	changeRouteenLessonStatus,
	changeLessonStatus,
	sendErrorToHOC
} from 'actions/actions';
// @import styles
import 'css/containers/home.scss';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
import { grouptoSlug } from 'js/utils/groupUtility';
// @import svgs
import { ReactComponent as Cart } from '../../../images/svg/cart.svg';

class Home extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			studentsList: null,
			showObservationPopup: false,
			showLessonPopup: false,
			showYoutubeVideoPopup: false,
			redirect: false,
			refresh: false,
			showIframePopup: false,
		};

		this.sourceArray = null;
		this.destinationArray = null;
		this.draggedObject = null;
		this.switchedLessonId = null;
		this.switchedLessonName = null;
		this.switchedActionLessonId = null;
		this.switchedActionLessonName = null;
		this.relatedActionLesson = null;

		this.fetchCalled = false;
		this.timeout = null;

		this.idleTime = 0;
		this.maxIdleTime = 60;
		this.checkInactivityInterval = null;
	}

	componentWillMount() {
		if (this.props.location.pathname === '/') {
			this.setState({
				redirect: true
			});
		}
	}

	componentDidMount() {
		if (this.props.students) {
			this.setState({
				switchedLesson: true,
				studentsList: this.props.students
			});
		}
		if (!this.state.redirect) {
			this.fetchIntialPeriodData();
		}
		let { groupId } = this.props;
		this.getGroup = this.props.groupList.groups.find(
			x => x.groupId === groupId
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.groupId !== this.props.groupId) {
			if (this.props.lessonTree) {
				this.fetchIntialPeriodData();
			}
		}
		if (this.props.lessonTree !== prevProps.lessonTree) {
			this.fetchIntialPeriodData();
		}
		if (prevProps.students !== this.props.students) {
			this.setState({
				switchedLesson: true,
				isStudentLoading: false,
				studentsList: this.props.students,
				isLoading: false
			});
		}
		if (this.props.lessons !== prevProps.lessons) {
			if (this.switchedLessonId) {
				this.props.changeLessonStep(
					this.switchedLessonId,
					this.props.groupId,
					this.props.token
				);
			}
			this.setState({
				isLoading: false
			});
		}

		if (this.props.lessons === 'nodata' && prevProps.lessons === 'nodata') {
			this.timeout = setTimeout(() => {
				this.setState({
					isLoading: false
				});
			}, 1000);
		}
	}

	componentWillUnmount() {
		this.untrackActivity();
		clearTimeout(this.timeout);
	}

	fetchIntialPeriodData = () => {
		this.fetchCalled = true;
		if (this.fetchCalled) {
			const { params } = this.props.match;
			const currentSeason = this.props.lessonTree.find(
				x => x.seasonName.toLowerCase() === params.season.toLowerCase()
			);

			let getPeriodFromURL = params.period;
			if (getPeriodFromURL.includes('-')) {
				getPeriodFromURL = getPeriodFromURL.replace(/-/g, ' ');
			}
			const currentPeriod = currentSeason.periods.find(
				x => x.periodName.toLowerCase() === getPeriodFromURL.toLowerCase()
			);

			const currentTopic = currentPeriod.topics.find(
				x =>
					x.topicName.replace(/-/g, ' ').toLowerCase() ===
					params.topic.replace(/-/g, ' ').toLowerCase()
			);
			this.props.fetchPeriodData(
				this.props.token,
				currentPeriod?.periodId,
				this.props.groupId,
				currentTopic?.topicId
			);

			this.setState(
				{
					isLoading: true,
					topicId: currentTopic?.topicId,
					periodId: currentPeriod.periodId,
					periodName: currentPeriod.periodName,
					periodImage: currentPeriod.periodPicture,
					periodsTabArray: currentSeason.periods,
					topicTabsArray: currentPeriod.topics
				},
				() => {
					this.fetchCalled = false;
				}
			);
		}
	};

	downloadProduct = (shopitemId, shipping, formData) => {
		const dataForm = new FormData();
		dataForm.append('shopitemId', shopitemId);

		if (shipping) {
			dataForm.append('shipping', shipping);
		}

		if (formData) {
			Object.keys(formData).forEach(key => {
				return dataForm.append(key, formData[key]);
			});
		}

		// // Display the key/value pairs of formdata
		// for (var pair of dataForm.entries()) {
		// 	console.log(pair[0] + ', ' + pair[1]);
		// }

		axiosPost(
			getshopitempurchase_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult && axiosResult.status === 'success') {
					this.fetchIntialPeriodData();
					// window.open(axiosResult.data.downloadUrl);
				}
			}
		);
	};

	setDraggedStudent = (array, draggableId) => {
		this.draggedStudent = array.filter(
			x => x.studentId === draggableId.toString()
		);
	};

	onDragEnd = result => {
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}
		const type = destination.droppableId.includes('routeen')
			? 'routeen'
			: 'normal';

		if (type === 'routeen') {
			destination.droppableId = destination.droppableId.replace('routeen_', '');
			source.droppableId = source.droppableId.replace('routeen_', '');
		} else {
			destination.droppableId = destination.droppableId.replace('normal_', '');
			source.droppableId = source.droppableId.replace('normal_', '');
		}

		if (destination.droppableId !== source.droppableId) {
			let arrayname = source.droppableId;
			let studentList = null;
			let currentLesson = null;
			let message = null;
			if (type === 'routeen') {
				this.setDraggedStudent(
					this.state.actionPlanStudentList[arrayname],
					draggableId
				);

				const step = this.state.actionPlanStudentDescription.find(
					x => x.step === source.droppableId
				);
				message = step.description;
				studentList = this.state.actionPlanStudentList;
			} else {
				this.setDraggedStudent(this.props.students[arrayname], draggableId);

				currentLesson = this.props.lessons.filter(
					x => x.lessonId === this.props.activeLessonId
				);

				message = currentLesson[0].stepdescriptions[source.droppableId];

				studentList = this.state.studentsList;
			}
			if (message.includes('#KIND')) {
				const studentName = this.draggedStudent[0].studentFirstname
					? this.draggedStudent[0].studentFirstname
					: '';
				message = message.replace(/#KIND/gi, studentName);
			}

			const sourceArray = studentList[source.droppableId];
			const sourceArrayClone = Array.from(sourceArray);
			this.sourceArray = sourceArrayClone;

			const draggableObject = sourceArray.filter(
				x => x.studentId === draggableId.toString()
			);

			this.draggedObject = draggableObject;

			const destinationArray = studentList[destination.droppableId];
			const destinationArrayClone = Array.from(destinationArray);
			this.destinationArray = destinationArrayClone;

			sourceArrayClone.splice(source.index, 1);
			this.sourceIndex = source.index;

			destinationArrayClone.splice(destination.index, 0, draggableObject[0]);
			this.destinationIndex = destination.index;

			this.sourceDroppableId = source.droppableId;
			this.destinationDroppableId = destination.droppableId;

			if (type === 'routeen') {
				this.setState({
					actionPlanStudentList: {
						...this.state.actionPlanStudentList,
						[source.droppableId]: sourceArrayClone,
						[destination.droppableId]: destinationArrayClone
					}
				});
			} else {
				this.setState({
					studentsList: {
						...this.state.studentsList,
						[source.droppableId]: sourceArrayClone,
						[destination.droppableId]: destinationArrayClone
					}
				});
			}

			const draggedLessonId =
				type === 'routeen'
					? this.switchedActionLessonId
					: this.props.activeLessonId;
			const draggedLesson =
				type === 'routeen' ? this.relatedActionLesson : currentLesson[0];
			const calledfrom = type === 'routeen' ? 'action plan' : 'group plan';

			// get pridicted state for student performance
			const dataForm = new FormData();
			dataForm.append('studentId', draggableId);
			dataForm.append('newStep', destination.droppableId);

			axiosPost(
				getstudentperformance_endpoint,
				dataForm,
				this.props.token,
				axiosResult => {
					if (type === 'routeen') {
						this.setState({
							getstudentperformance_endpoint:
								axiosResult.data.studentPerformance
						});
					} else {
						this.setState({
							draggedStudentPerformance: axiosResult.data.studentPerformance
						});
					}
				}
			);

			this.updateDragWrapper(
				this.draggedStudent[0],
				destination.droppableId,
				source.droppableId,
				draggableId,
				draggedLessonId,
				message,
				draggedLesson,
				calledfrom
			);
		}
	};

	cancelDrag = () => {
		this.sourceArray.splice(this.sourceIndex, 0, this.draggedObject[0]);
		this.destinationArray.splice(this.destinationIndex, 1);

		this.setState({
			studentsList: {
				...this.state.studentsList,
				[this.sourceDroppableId]: this.sourceArray,
				[this.destinationDroppableId]: this.destinationArray
			},
			actionPlanStudentList: {
				...this.state.actionPlanStudentList,
				[this.sourceDroppableId]: this.sourceArray,
				[this.destinationDroppableId]: this.destinationArray
			}
		});
	};

	handleObservertion = (
		token,
		lessonId,
		lessonName,
		groupId,
		periodId,
		topicId
	) => {
		this.props.startObservation(token, lessonId, groupId, periodId, topicId);
		this.openLessonSwitch(lessonId, lessonName);
	};

	hideStudentPopup = () => {
		TweenMax.to('.popupWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState(
						{
							showObservationPopup: false
						},
						() => {
							this.trackActivity();
						}
					);
				}, 200);
			}
		});
	};

	trackActivity() {
		clearInterval(this.checkInactivityInterval);
		this.checkInactivityInterval = setInterval(
			() => this.checkInactivity(),
			1000
		);
		document.addEventListener('mousemove', this.handleCursorMove);
	}

	untrackActivity() {
		document.removeEventListener('mousemove', this.handleCursorMove);
		clearInterval(this.checkInactivityInterval);
	}

	checkInactivity() {
		this.idleTime++;
		if (this.idleTime === this.maxIdleTime) {
			this.idleTime = 0;
			clearInterval(this.checkInactivityInterval);
			this.setState({ refresh: true });
		}
	}

	refreshData() {
		this.setState({ refresh: false }, () => {
			this.fetchIntialPeriodData();
			this.trackActivity();
		});
	}

	handleCursorMove = () => {
		this.idleTime = 0;
		if (this.state.refresh) {
			this.refreshData();
		}
	};

	openLessonSwitch = (lessonId, lessonName) => {
		this.setState(
			{
				isLessonGroupPlan: true,
				switchedLesson: false,
				isStudentLoading: true
			},
			() => {
				this.switchedLessonId = lessonId;
				this.switchedLessonName = lessonName;
				if (this.getGroup?.numberOfTeachers > 1) {
					this.trackActivity();
				}
			}
		);
	};

	handleLessonSwitch = (lessonId, lessonName) => {
		if (this.switchedLessonId !== lessonId) {
			this.setState(
				{
					isLessonGroupPlan: true,
					switchedLesson: false,
					isStudentLoading: true
				},
				() => {
					this.switchedLessonId = lessonId;
					this.switchedLessonName = lessonName;
					this.props.changeLessonStep(
						lessonId,
						this.props.groupId,
						this.props.token
					);
					if (this.getGroup?.numberOfTeachers > 1) {
						this.trackActivity();
					}
				}
			);
		} else {
			this.setState(
				{
					isLessonGroupPlan: false
				},
				() => {
					this.switchedLessonId = null;
					this.switchedLessonName = null;
					this.untrackActivity();
				}
			);
		}
	};

	getBreadCrumb = (topicId, periodId) => {
		this.setState({
			topicId,
			periodId
		});
	};

	handleLesson = (lessonId, lessonStatus, type) => {
		type === 'lesson'
			? this.props.changeLessonStatus(
					this.state.periodId,
					lessonId,
					this.props.groupId,
					lessonStatus,
					this.state.topicId,
					this.props.token
			  )
			: this.props.changeRouteenLessonStatus(
					this.state.periodId,
					lessonId,
					this.props.groupId,
					lessonStatus,
					this.state.topicId,
					this.props.token
			  );
	};

	handleActionLessonSwitch = (actionlessonId, routeenLessonId) => {
		if (this.switchedActionLessonId !== actionlessonId) {
			this.setState(
				{
					actionPlanStudentDescription: '',
					isActionLessenPlan: true,
					isActionPlanLoading: true,
					activeroutinelessonId: routeenLessonId
				},
				() => {
					this.switchedActionLessonId = actionlessonId;
					this.getActionPlan(actionlessonId, routeenLessonId);
					const element = document.querySelector('#routeenLessonPlan');
					window.scroll({
						behavior: 'smooth',
						left: 0,
						top: element.offsetTop
					});
					if (this.getGroup?.numberOfTeachers > 1) {
						this.trackActivity();
					}
				}
			);
		} else {
			this.setState(
				{
					isActionLessenPlan: false,
					isActionPlanLoading: false,
					activeroutinelessonId: null
				},
				() => {
					this.switchedActionLessonId = null;
					this.switchedActionLessonName = null;
					this.relatedActionLesson = null;
					this.untrackActivity();
				}
			);
		}
	};

	getActionPlan = (lessonId, routinelessonId) => {
		const dataForm = new FormData();
		dataForm.append('groupId', this.props.groupId);
		dataForm.append('lessonId', lessonId);
		dataForm.append('routinelessonId', routinelessonId);

		axiosPost(actionplan_endpoint, dataForm, this.props.token, axiosResult => {
			this.switchedActionLessonName = axiosResult.data.relatedlesson.lessonName;
			this.relatedActionLesson = axiosResult.data.relatedlesson;
			this.setState({
				isActionPlanLoading: false,
				actionPlanStudentList: axiosResult.data.students,
				actionPlanStudentDescription: axiosResult.data.stepdescriptions
			});
		});
	};

	showVideoPopup = (videoTitle, url, lessonTitle, image) => {
		this.setState(
			{
				showYoutubeVideoPopup: true,
				youtubeUrl: url,
				videoTitle: videoTitle,
				videoLessonTitle: lessonTitle,
				VideoImage: image
			},
			() => {
				TweenMax.to('#videoPopup', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	hideYoutubePopup = () => {
		TweenMax.to('#videoPopup', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						showYoutubeVideoPopup: false
					});
				}, 200);
			}
		});
	};

	showIframePopup = (url) => {
		this.setState(
			{
				showIframePopup: true,
				iframeUrl: url,
			},
			() => {
				TweenMax.to('#iframePopup', 0.3, {
					autoAlpha: 1
				});
			}
		);
	};

	hideIframePopup = () => {
		TweenMax.to('#iframePopup', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				setTimeout(() => {
					this.setState({
						showIframePopup: false
					});
				}, 200);
			}
		});
	};


	handleStudentClick = (arrayname, studentId, type = 'normal') => {
		let currentLesson = null;
		let message = null;
		if (type === 'routeen') {
			this.setDraggedStudent(
				this.state.actionPlanStudentList[arrayname],
				studentId
			);
			currentLesson = this.state.actionPlanStudentDescription;
			const step = currentLesson.find(x => x.step === arrayname);
			message = step.description;
		} else {
			this.setDraggedStudent(this.props.students[arrayname], studentId);
			currentLesson = this.props.lessons.filter(
				x => x.lessonId === this.props.activeLessonId
			);
			message = currentLesson[0].stepdescriptions[arrayname];
		}

		if (message.includes('#KIND')) {
			const studentName = this.draggedStudent[0].studentFirstname
				? this.draggedStudent[0].studentFirstname
				: '';
			message = message.replace(/#KIND/gi, studentName);
		}

		const draggedLessonId =
			type === 'routeen'
				? this.switchedActionLessonId
				: this.props.activeLessonId;
		const draggedLesson =
			type === 'routeen' ? this.relatedActionLesson : currentLesson[0];
		const calledfrom = type === 'routeen' ? 'action plan' : 'group plan';

		this.updateDragWrapper(
			this.draggedStudent[0],
			arrayname,
			arrayname,
			studentId,
			draggedLessonId,
			message,
			draggedLesson,
			calledfrom
		);
	};

	updateDragWrapper = (
		draggedStudent,
		draggedTo,
		draggedFrom,
		draggedStudentId,
		draggedLessonId,
		message,
		draggedLesson,
		calledfrom
	) => {
		this.setState(
			{
				draggedStudent: draggedStudent,
				draggedTo,
				draggedFrom,
				draggedLessonId: draggedLessonId,
				draggedMessage: message,
				draggedLesson: draggedLesson,
				draggedStudentPerformance: draggedStudent.studentPerformance,
				showObservationPopup: true,
				calledfrom: calledfrom
			},
			() => {
				TweenMax.to('.popupWrapper', 0.3, {
					autoAlpha: 1
				});
				this.untrackActivity();
			}
		);
	};

	render() {
		const {
			match,
			lessons,
			token,
			groupId,
			activeLessonId,
			students,
			stepdescriptions,
			routinelessons
		} = this.props;
		const routinelessonsDone = (routinelessons && routinelessons !== 'nodata') && routinelessons.filter((routinelesson) => routinelesson.routinelessonStatus === 'planned');
		const routinelessonsTodo = (routinelessons && routinelessons !== 'nodata') && routinelessons.filter((routinelesson) => routinelesson.routinelessonStatus === 'todo' || routinelesson.routinelessonStatus == 'done');

		// console.log('routeen lesson: ', routinelessons);
		if (this.state.redirect) {
			return <Redirect to={this.props.initialData.defaultUrl} />;
		}
		return (
			<Fragment>
				<Helmet>
					<title>Mijn groep</title>
				</Helmet>
				<div className={`seasonWrapper ${match.params.season}_Season`}>
					{this.state.periodsTabArray && (
						<div className="periodWrapper">
							<ul className="periodsTab">
								{this.state.periodsTabArray.map((item, i) => (
									<li
										key={i}
										className={`${
											match.params.period.includes('-')
												? match.params.period
														.replace(/-/g, ' ')
														.toLowerCase() === item.periodName.toLowerCase() &&
												  'active'
												: match.params.period.toLowerCase() ===
														item.periodName.toLowerCase() && 'active'
										}`}
									>
										<Link
											to={`/lessen/${match.params.season.toLowerCase()}/${
												item.periodName.includes(' ')
													? item.periodName.replace(/ /g, '-').toLowerCase()
													: item.periodName.toLowerCase()
											}/${this.state.topicTabsArray[0].topicName
												.replace(/ /g, '-')
												.toLowerCase()}`}
										>
											{item.periodName}
										</Link>
									</li>
								))}
							</ul>
							<section
								className={`topicsWrapper ${
									match.params.shop === 'lesmaterialen'
										? 'lesmaterialen_topic'
										: `${match.params.topic}_topic`
								}`}
							>
								<div className="pageContainer">
									<div className="topicHeader">
										<div className="periodImage">
											<img
												src={this.state.periodImage.replace(
													/(\.[^.]+)$/,
													'-180x180$1'
												)}
												srcSet={`${this.state.periodImage.replace(
													/(\.[^.]+)$/,
													'-360x360$1'
												)} 2x`}
												alt=""
											/>
										</div>
										<div className="topicLessonContainer">
											<div className="flexBlock flexSpaceCenter">
												<ul className="topicTabs">
													{this.state.topicTabsArray.map((item, i) => (
														<li
															className={`${item.topicName
																.replace(/ /g, '-')
																.toLowerCase()}_topic`}
															key={`${item.topicName}_${item.topicId}`}
														>
															<Link
																to={`/lessen/${match.params.season.toLowerCase()}/${match.params.period.toLowerCase()}/${item.topicName
																	.replace(/ /g, '-')
																	.toLowerCase()}`}
															>
																{item.topicName}
															</Link>
														</li>
													))}
													{this.props.shopItemsList &&
														this.props.shopItemsList.length > 0 && (
															<li className={`lesmaterialen_topic`}>
																<Link
																	to={`/lessen/${match.params.season.toLowerCase()}/${match.params.period.toLowerCase()}/${match.params.topic.toLowerCase()}/lesmaterialen`}
																>
																	Lesmaterialen
																	<Cart />
																</Link>
															</li>
														)}
												</ul>
												{(app_name !== 'wijzneus') && (
														<Link
															className="groupLink"
															to={{
																pathname: `/groep/${grouptoSlug(
																	this.getGroup.groupName
																)}/groepsoverzicht/${match.params.season.toLowerCase()}/${match.params.period.toLowerCase()}`,
																state: {
																	topic:
																		this.state.topicTabsArray[
																			Number(this.state.topicId) - 1
																		]
																}
															}}
														>
															Groepsoverzicht {this.state.periodName}
														</Link>
												)}
											</div>
                                            {/*
										      * HIDE LESSONS
											  *

											{!this.state.isLoading ? (
												lessons && (
													<div
														className={`flexWrapper lessonsWrapper ${
															lessons.length > 2 ? 'flexWrap' : ''
														}`}
													>
														{match.params.shop !== 'lesmaterialen' &&
															lessons !== 'nodata' &&
															lessons.map((item, i) => (
																<LessonSection
																	key={i}
																	item={item}
																	lessonStatus={
																		item.lessonStatus === 'done' ? 0 : 1
																	}
																	token={token}
																	groupId={groupId}
																	periodId={this.state.periodId}
																	topicId={this.state.topicId}
																	activeLessonId={activeLessonId}
																	isLessonGroupPlan={
																		this.state.isLessonGroupPlan
																	}
																	showVideoPopup={this.showVideoPopup}
																	showIframePopup={this.showIframePopup}
																	handleObservertion={this.handleObservertion}
																	handleLessonSwitch={this.handleLessonSwitch}
																	handleLesson={this.handleLesson}
																/>
															))}
														{match.params.shop === 'lesmaterialen' && (
															<section className="lessonSection flexWrapper">
																<h1 className="headingStyle">
																	Lesmaterialenwinkel
																</h1>
																<p>
																	Behalve de lesmaterialen die onderdeel zijn
																	van Onderbouwd Online zijn er ook mooie
																	aanvullende materialen van Klassewerk of
																	andere uitgevers verkrijgbaar. Je kunt deze
																	gebruiken om je aanbod nog verder te verrijken
																	en te variëren.
																</p>
															</section>
														)}
													</div>
												)
											) : (
												<PlaceHolderLoader
													type="lesson"
													rows={1}
													columns={2}
													innerClass={'flexWrapper lessonsWrapper'}
												/>
											)}
											*/}

										{match.params.shop !== 'lesmaterialen' &&
											(!this.state.isLoading ? (
												routinelessons &&
												routinelessons !== 'nodata' && (
													<div className="lessonsWrapper">
														<RoutineLessonWrapper
															token={token}
															routinelessons={routinelessonsDone}
															isActionLessenPlan={this.state.isActionLessenPlan}
															actionPlanStudentDescription={
																this.state.actionPlanStudentDescription
															}
															actionPlanStudentList={
																this.state.actionPlanStudentList
															}
															isActionPlanLoading={this.state.isActionPlanLoading}
															switchedActionLessonId={this.switchedActionLessonId}
															showVideoPopup={this.showVideoPopup}
															handleLesson={this.handleLesson}
															handleActionLessonSwitch={
																this.handleActionLessonSwitch
															}
															routinelessonId={this.state.activeroutinelessonId}
															lessonName={this.switchedActionLessonName}
															onDragEnd={this.onDragEnd}
															handleRefresh={() =>
																this.getActionPlan(
																	this.switchedActionLessonId,
																	this.state.activeroutinelessonId
																)
															}
															handleStudentClick={this.handleStudentClick}
														/>
													</div>
												)
											) : (
												<PlaceHolderLoader
													type="lesson"
													rows={1}
													columns={6}
													outerClass="lessonLayout"
													innerClass="flexWrapper"
												/>
											))}
										</div>
									</div>
									<section className="topicContainer">
										{this.state.isLessonGroupPlan &&
											students &&
											students !== 'nodata' &&
											this.state.switchedLesson && (
												<LessonDetail
													token={token}
													stepdescriptions={stepdescriptions}
													studentsList={this.state.studentsList}
													lessonId={this.switchedLessonId}
													lessonName={`Groepsplan ${this.switchedLessonName}`}
													onDragEnd={this.onDragEnd}
													handleStudentClick={this.handleStudentClick}
													handleRefresh={this.fetchIntialPeriodData}
												/>
											)}

										{this.state.isStudentLoading && (
											<PlaceHolderLoader
												type="list"
												rows={5}
												columns={6}
												outerClass="groupLayout"
												innerClass="flexWrapper"
											/>
										)}

										{match.params.shop !== 'lesmaterialen' &&
											(!this.state.isLoading ? (
												routinelessons &&
												routinelessons !== 'nodata' &&
												routinelessonsTodo.length > 0 && (
													<RoutineLessonWrapper
														token={token}
														routinelessons={routinelessonsTodo}
														isActionLessenPlan={this.state.isActionLessenPlan}
														hidePlan={true}
														actionPlanStudentDescription={
															this.state.actionPlanStudentDescription
														}
														actionPlanStudentList={
															this.state.actionPlanStudentList
														}
														isActionPlanLoading={this.state.isActionPlanLoading}
														switchedActionLessonId={this.switchedActionLessonId}
														showVideoPopup={this.showVideoPopup}
														handleLesson={this.handleLesson}
														handleActionLessonSwitch={
															this.handleActionLessonSwitch
														}
														routinelessonId={this.state.activeroutinelessonId}
														lessonName={this.switchedActionLessonName}
														onDragEnd={this.onDragEnd}
														handleRefresh={() =>
															this.getActionPlan(
																this.switchedActionLessonId,
																this.state.activeroutinelessonId
															)
														}
														handleStudentClick={this.handleStudentClick}
													/>
												)
											) : (
												<PlaceHolderLoader
													type="lesson"
													rows={1}
													columns={6}
													outerClass="lessonLayout"
													innerClass="flexWrapper"
												/>
											))}
										{match.params.shop === 'lesmaterialen' &&
											(this.props.shopItemsList.length > 0 ? (
												<ProductWrapper
													shopItemsList={this.props.shopItemsList}
													downloadProduct={this.downloadProduct}
													schoolDisplayname={
														this.props.userData?.schoolDisplayname
													}
													userData={this.props.userData}
												/>
											) : (
												<PlaceHolderLoader
													type="lesson"
													rows={1}
													columns={6}
													outerClass="lessonLayout"
													innerClass="flexWrapper"
												/>
											))}

										{this.props.periodFail && (
											<div className="noteWrapper">
												<p
													dangerouslySetInnerHTML={{
														__html: this.props.periodFail
													}}
												/>
											</div>
										)}
									</section>
								</div>
							</section>
						</div>
					)}
				</div>
				{this.state.showObservationPopup && (
					<ObservationPopup
						draggedStudent={this.state.draggedStudent}
						stepTo={this.state.draggedTo}
						stepFrom={this.state.draggedFrom}
						message={this.state.draggedMessage}
						lessonId={this.state.draggedLessonId}
						routinelessonId={this.state.activeroutinelessonId}
						hidepopup={this.hideStudentPopup}
						cancelDrag={this.cancelDrag}
						calledfrom={this.state.calledfrom}
						updateActionPlan={this.getActionPlan}
						clickedLesson={this.state.draggedLesson}
						studentPerformance={this.state.draggedStudentPerformance}
					/>
				)}
				{this.state.showYoutubeVideoPopup && (
					<YoutubeVideoPopup
						closePopup={this.hideYoutubePopup}
						url={this.state.youtubeUrl}
						image={this.state.VideoImage}
						videoTitle={this.state.videoTitle}
						lessonTitle={this.state.videoLessonTitle}
					/>
				)}
				{this.state.showIframePopup && (
					<IframePopup
						closePopup={this.hideIframePopup}
						url={this.state.iframeUrl}
					/>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	initialData: state.initialData,
	userData: state.initialData.userData,
	token: state.initialData.token,
	lessons: state.initialData.lessons,
	shopItemsList: state.initialData.shopItemsList,
	lessonTree: state.initialData.lessonTree,
	routinelessons: state.initialData.routinelessons,
	students: state.initialData.students,
	periodId: state.initialData.periodId,
	topicId: state.initialData.topicId,
	groupId: state.initialData.groupId,
	activeLessonId: state.initialData.activeLessonId,
	stepdescriptions: state.initialData.stepdescriptions,
	groupList: state.initialData.groupList,
	periodFail: state.initialData.periodFail
});

export default connect(mapStateToProps, {
	startObservation,
	changeLessonStep,
	fetchPeriodData,
	changeRouteenLessonStatus,
	changeLessonStatus,
	sendErrorToHOC
})(Home);
