// @import packages
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// @import constants
import {
	learningstyle_endpoint,
	learningstyleupdate_endpoint
} from '../constants/endpoints';
// @import actions
import { setStudentList, sendErrorToHOC } from '../../actions/actions';
// @import styles
import '../../css/containers/studentSocialProfile.scss';
// @import utils
import { axiosPost } from '../utils/axiosCall';
import { breadCrumbRoute } from '../utils/studentBreadCrumbs';

export class StudentLearningStyle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			currentStudent: null
		};
	}

	componentDidMount() {
		if (this.props.studentList) {
			this.setInitialStates();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.studentList !== this.props.studentList) {
			this.setInitialStates();
		}
	}

	setInitialStates = () => {
		let { groupname } = this.props.match.params;
		groupname = groupname.replace(/-/g, ' ');

		const getGroup = this.props.groupList.groups.find(
			x =>
				x.groupName.replace(/-/g, ' ').replace(/\//g, ' ').toLowerCase() ===
				groupname
		);

		this.props.setStudentList(
			getGroup.groupId,
			getGroup.groupName,
			getGroup.students
		);

		const getStudent = getGroup.students.find(
			x =>
				x.studentDisplayname.replace(/-/g, ' ').toLowerCase() ===
				this.props.match.params.studentname.replace(/-/g, ' ')
		);

		// const getStudent = this.props.studentList.students.find(
		//   x =>
		//     x.studentDisplayname.toLowerCase() ===
		//     this.props.match.params.studentname.replace(/-/g, ' ')
		// );

		this.setState({
			currentStudent: getStudent,
			studentDisplayname: getStudent.studentDisplayname,
			studentFirstname: getStudent.studentFirstname
		});

		this.getOverviewData(getStudent.studentId, this.props.token);
	};

	getOverviewData = (studentId, token) => {
		this.setState({
			isLoading: true,
			lessons: null
		});

		const dataForm = new FormData();
		dataForm.append('studentId', studentId);

		this.props.location.pathname.includes('leerlingportfolio');
		axiosPost(
			learningstyle_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState({
						isLoading: false,
						data: axiosResult.data
					});
				}
			}
		);
	};

	updateStudentProfile = (studentId, learningstyleId, answer, token) => {
		const dataForm = new FormData();
		dataForm.append('studentId', studentId);
		dataForm.append('learningstyleId', learningstyleId);
		dataForm.append('answer', answer);
		axiosPost(
			learningstyleupdate_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					const dataForm = new FormData();
					dataForm.append('studentId', studentId);
					axiosPost(
						learningstyle_endpoint,
						dataForm,
						this.props.token,
						axiosResult => {
							if (axiosResult.status === 'success') {
								this.setState({
									data: axiosResult.data
								});
							}
						}
					);
				}
			}
		);
	};

	handleChange(event, id) {
		this.updateStudentProfile(
			this.state.currentStudent.studentId,
			id,
			event.target.checked ? 1 : 0,
			this.props.token
		);
	}

	getTopicName = topicName => {
		this.setState({
			topicName
		});
	};

	render() {
		return (
			<div className="mainContainer">
				<Helmet>
					<title>Mijn groep</title>
				</Helmet>
				{this.state.currentStudent && (
					<nav className="breadCrumbMenu">
						<ul id="breadCrumb">
							<li>
								<span>{this.state.currentStudent.studentDisplayname}</span>
							</li>
							<li>
								{breadCrumbRoute.map((item, i) => (
									<NavLink
										to={`/leerling/${this.props.groupName
											.replace(/ /g, '-')
											.replace(/\//g, '-')
											.toLowerCase()}/${this.state.currentStudent.studentDisplayname
											.replace(/ /g, '-')
											.toLowerCase()}${item.slug}`}
										key={i}
										exact
										className={
											this.props.location.pathname.includes(
												item.name.replace(/ /g, '-').toLowerCase()
											)
												? 'active'
												: ''
										}
									>
										{item.name}
									</NavLink>
								))}
								<NavLink
									to={`/${this.props.groupName
										.replace(/ /g, '-')
										.replace(/\//g, '-')
										.toLowerCase()}/${this.state.currentStudent.studentDisplayname
										.replace(/ /g, '-')
										.toLowerCase()}/leerlingportfolio`}
									exact
									className={
										this.props.location.pathname.includes('leerlingportfolio')
											? 'active'
											: ''
									}
								>
									Leerlingportfolio
								</NavLink>
							</li>
						</ul>
					</nav>
				)}
				<div className="pageContainer">
					{this.state.data && (
						<Fragment>
							<h1 className="headingStyle">Onderwijsbehoeften</h1>
							{this.state.data.map((item, i) => {
								// let totalLength = item.statements.length;
								const getCheckedLength = item.statements.filter(
									x => x.learningstylestudentAnswer === '1'
								);
								return (
									<div key={i} className="socialWrapper">
										<img
											src={item.learningstylePicture.replace(
												/(\.[^.]+)$/,
												'-200x200$1'
											)}
											srcSet={`${item.learningstylePicture.replace(
												/(\.[^.]+)$/,
												'-400x400$1'
											)} 2x`}
											alt=""
										/>
										<div className={'socialContent'}>
											<h2>
												{item.learningstyletopicName}{' '}
												{getCheckedLength.length > 0 &&
													`(${getCheckedLength.length})`}
											</h2>
											{item.statements && (
												<ul>
													{item.statements.map((res, i) => (
														<li key={i}>
															<div>
																<input
																	type="checkbox"
																	name={res.learningstyleId}
																	id={`${res.learningstyleId}-ja`}
																	value="1"
																	checked={
																		res.learningstylestudentAnswer === '1'
																	}
																	onChange={event =>
																		this.handleChange(
																			event,
																			res.learningstyleId
																		)
																	}
																/>
																<span>
																	{res.learningstyleText.replace(
																		/#kind/gi,
																		this.state.studentFirstname
																	)}
																</span>
															</div>
														</li>
													))}
												</ul>
											)}
										</div>
									</div>
								);
							})}
						</Fragment>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	token: state.initialData.token,
	students: state.initialData.students,
	studentList: state.initialData.studentList,
	groupId: state.initialData.groupId,
	groupName: state.initialData.groupName,
	groupList: state.initialData.groupList,
	seasonId: state.initialData.seasonId,
	periodId: state.initialData.periodId,
	topicId: state.initialData.topicId,
	lessonTree: state.initialData.lessonTree
});

export default connect(mapStateToProps, { setStudentList, sendErrorToHOC })(
	StudentLearningStyle
);
