import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
// @import constants
import {
	lessonadd_endpoint,
	lessonedit_endpoint,
	lessondelete_endpoint,
	lessonarchive_endpoint
} from 'js/constants/endpoints';
// @import components
import ImageEditor from 'js/models/ImageEditor';
// @import svgs
import { ReactComponent as Cross } from 'images/svg/cross.svg';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
// @import utils
import { axiosPost } from 'js/utils/axiosCall';
// @import styles
import 'css/components/popup/lessonAddEditPopup.scss';

class LessonAddEdit extends Component {
	constructor(props) {
		super(props);
		let displayDate;
		if (this.props.popupdata) {
			const date = this.props.popupdata.lesson?.general.lessonPublicationdate;
			displayDate = moment(date ? new Date(date) : new Date()).format(
				'DD-MM-YYYY'
			);
		}
		this.state = {
			activeStep: 0,
			lessonName: this.props.popupdata.lesson?.general.lessonName,
			lessonIntroduction:
				this.props.popupdata.lesson?.general.lessonIntroduction || '',
			lessonPublicationdate: displayDate,
			goalId: this.props.popupdata.lesson?.general.lessonGoal || '',
			topicId: this.props.popupdata?.topicId || '',
			periodId: this.props.popupdata?.periodId || '',
			seasonId: this.props.popupdata?.seasonId || '',
			blockersstimulants_stimulants:
				this.props.popupdata.lesson?.blockersstimulants.lessonStimulants || '',
			blockersstimulants_blockers:
				this.props.popupdata.lesson?.blockersstimulants.lessonBlockers || '',
			lessonType1: this.props.popupdata.lesson?.material.media1.type || 'link',
			lessonMedia1Delete: '',
			lessonMedia1: this.props.popupdata.lesson?.material.media1.url || '',
			lessonMedia1File: '',
			lessonMedia1Title: 'Open les',
			lessonType2: this.props.popupdata.lesson?.material.media2.type || 'link',
			lessonMedia2Delete: '',
			lessonMedia2: this.props.popupdata.lesson?.material.media2.url || '',
			lessonMedia2File: '',
			lessonMedia2Title:
				this.props.popupdata.lesson?.material.media2.title || '',
			lessonType3: this.props.popupdata.lesson?.material.media3.type || 'link',
			lessonMedia3Delete: '',
			lessonMedia3: this.props.popupdata.lesson?.material.media3.url || '',
			lessonMedia3File: '',
			lessonMedia3Title:
				this.props.popupdata.lesson?.material.media3.title || '',
			lessonWorksheet1Delete: '',
			lessonWorksheet1:
				this.props.popupdata.lesson?.worksheet.worksheet1.url || '',
			lessonWorksheet1File: '',
			lessonWorksheet1Title:
				this.props.popupdata.lesson?.worksheet.worksheet1.title || '',
			lessonWorksheet2Delete: '',
			lessonWorksheet2:
				this.props.popupdata.lesson?.worksheet.worksheet2.url || '',
			lessonWorksheet2File: '',
			lessonWorksheet2Title:
				this.props.popupdata.lesson?.worksheet.worksheet2.title || '',
			lessonVideo1Url: this.props.popupdata.lesson?.videos.video1.url || '',
			lessonVideo1Text: this.props.popupdata.lesson?.videos.video1.title || '',
			lessonVideo2Url: this.props.popupdata.lesson?.videos.video2.url || '',
			lessonVideo2Text: this.props.popupdata.lesson?.videos.video2.title || '',
			lessonVideo3Url: this.props.popupdata.lesson?.videos.video3.url || '',
			lessonVideo3Text: this.props.popupdata.lesson?.videos.video3.title || '',
			lessonStepA:
				this.props.popupdata.lesson?.steps.A.description_active || '',
			lessonStepAPassive:
				this.props.popupdata.lesson?.steps.A.description_passive || '',
			lessonStepADescription:
				this.props.popupdata.lesson?.steps.A.description_description || '',
			lessonParentAType:
				this.props.popupdata.lesson?.steps.A.parentlesson.type || 'file',
			lessonParentAFile: '',
			lessonParentAUrl:
				this.props.popupdata.lesson?.steps.A.parentlesson.media || '',
			lessonStepB:
				this.props.popupdata.lesson?.steps.B.description_active || '',
			lessonStepBPassive:
				this.props.popupdata.lesson?.steps.B.description_passive || '',
			lessonStepBDescription:
				this.props.popupdata.lesson?.steps.B.description_description || '',
			lessonParentBType:
				this.props.popupdata.lesson?.steps.B.parentlesson.type || 'file',
			lessonParentBFile: '',
			lessonParentBUrl:
				this.props.popupdata.lesson?.steps.B.parentlesson.media || '',
			lessonStepC:
				this.props.popupdata.lesson?.steps.C.description_active || '',
			lessonStepCPassive:
				this.props.popupdata.lesson?.steps.C.description_passive || '',
			lessonStepCDescription:
				this.props.popupdata.lesson?.steps.C.description_description || '',
			lessonParentCType:
				this.props.popupdata.lesson?.steps.C.parentlesson.type || 'file',
			lessonParentCFile: '',
			lessonParentCUrl:
				this.props.popupdata.lesson?.steps.C.parentlesson.media || '',
			lessonStepD:
				this.props.popupdata.lesson?.steps.D.description_active || '',
			lessonStepDPassive:
				this.props.popupdata.lesson?.steps.D.description_passive || '',
			lessonStepDDescription:
				this.props.popupdata.lesson?.steps.D.description_description || '',
			lessonParentDType:
				this.props.popupdata.lesson?.steps.D.parentlesson.type || 'file',
			lessonParentDFile: '',
			lessonParentDUrl:
				this.props.popupdata.lesson?.steps.D.parentlesson.media || '',
			lessonStepE:
				this.props.popupdata.lesson?.steps.E.description_active || '',
			lessonStepEDescription:
				this.props.popupdata.lesson?.steps.E.description_description || '',
			periodsArray: null,
			topicGoals: null,
			loaderRequested: false
		};

		this.escFunction = this.escFunction.bind(this);
	}

	componentDidMount() {
		//this.filterPeriods(this.state.seasonId);
		this.filterGoals(this.props.popupdata.topicId);
		document.addEventListener('keydown', this.escFunction, false);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
	}

	handleTabClick = index => {
		this.setState({
			activeStep: index
		});
		TweenMax.set('.stepsWrapper', { display: 'none' });
		TweenMax.set(`.step_${index}`, { display: 'block' });
	};

	filterGoals = topicId => {
		const topic = this.props.goals.find(item => item.topicId === topicId);
		this.setState({
			topicGoals: topic ? topic.goals : null,
		});
	};

	filterPeriods = seasonId => {
		if (!seasonId) return;
		this.setState({
			periodsArray: this.props.data.filter(item => {
				return item.seasonId === seasonId;
			})[0].periods
		});
	};

	escFunction(event) {
		if (event.keyCode === 27) {
			console.log('Period add edit');
			this.props.hidepopup();
		}
	}

	handleInputChange = event => {
		// console.log('selectchange: ', event.target.name);
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleFileChange = event => {
		this.setState({
			[event.target.name]: event.target.files[0]
		});
	};

	handleDateChange = event => {
		this.setState({
			date: event.target.value
		});
	};

	openDeletePopup = () => {
		TweenMax.to('.addEditWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.addEditWrapper', { display: 'none' });
				TweenMax.set('.deleteWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.deleteWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	hideDeletePopup = () => {
		TweenMax.to('.deleteWrapper', 0.3, {
			autoAlpha: 0,
			onComplete: () => {
				TweenMax.set('.deleteWrapper', { display: 'none' });
				TweenMax.set('.addEditWrapper', { display: 'block', autoAlpha: 0 });
				TweenMax.to('.addEditWrapper', 0.3, { autoAlpha: 1 });
			}
		});
	};

	handleArchive = status => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('lessonId', this.props.popupdata.lesson?.lessonId);
		dataForm.append('newStatus', status);
		axiosPost(
			lessonarchive_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateData();
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	handleDelete = () => {
		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		const dataForm = new FormData();
		dataForm.append('lessonId', this.props.popupdata.lesson?.lessonId);
		axiosPost(
			lessondelete_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					this.setState(
						{
							isWaiting: false
						},
						() => {
							const loader = document.querySelector('.circleLoader');
							const check = loader.querySelector('.draw');

							loader.classList.add('loadComplete');
							check.classList.add('checkmark');
						}
					);
					this.props.updateData();
					setTimeout(() => {
						this.props.hidepopup();
					}, 500);
				} else {
					this.setState({
						isWaiting: false,
						loaderRequested: false,
						errorMessage: axiosResult.failreason
					});
				}
			}
		);
	};

	submitrequest = () => {
		if (!this.state.goalId || !this.state.lessonName) {
			this.setState({
				isWaiting: false,
				loaderRequested: false,
				errorMessage: 'please fill all required fields'
			});
			return;
		}

		let date;
		if (this.state.lessonPublicationdate) {
			date = this.state.lessonPublicationdate.replace(/\//g, '-');
			var res = date.split('-', 3);
			// date = `${res[1]}-${res[0]}-${res[2]}`;
			// fix for firefox
			date = `${res[1]}/${res[0]}/${res[2]}`;
			date = moment(new Date(date)).format('YYYY-MM-DD');
		}

		let url;
		if (this.props.name === 'add') {
			url = lessonadd_endpoint;
		}
		if (this.props.name === 'edit') {
			url = lessonedit_endpoint;
		}

		const dataForm = new FormData();
		if (this.props.name === 'edit') {
			dataForm.append('lessonId', this.props.popupdata.lesson?.lessonId);
			dataForm.append(
				'lessonActive',
				this.props.popupdata.lesson?.general.lessonActive
			);
		}
		dataForm.append('goalId', this.state.goalId);
		dataForm.append('topicId', this.state.topicId);
		dataForm.append('periodId', this.state.periodId);
		dataForm.append('seasonId', this.state.seasonId);

		dataForm.append('lessonName', this.state.lessonName);
		dataForm.append('lessonIntroduction', this.state.lessonIntroduction);
		dataForm.append('lessonPublicationdate', date);

		dataForm.append(
			'blockersstimulants_stimulants',
			this.state.blockersstimulants_stimulants
		);
		dataForm.append(
			'blockersstimulants_blockers',
			this.state.blockersstimulants_blockers
		);

		dataForm.append('lessonType1', this.state.lessonType1);
		dataForm.append(
			'lessonMedia1',
			this.state.lessonType1 !== 'file'
				? this.state.lessonMedia1
				: this.state.lessonMedia1File
		);
		dataForm.append('lessonMedia1Title', this.state.lessonMedia1Title);
		dataForm.append('lessonMedia1Delete', this.state.lessonMedia1Delete);
		dataForm.append('lessonType2', this.state.lessonType2);
		dataForm.append(
			'lessonMedia2',
			this.state.lessonType2 !== 'file'
				? this.state.lessonMedia2
				: this.state.lessonMedia2File
		);
		dataForm.append('lessonMedia2Delete', this.state.lessonMedia2Delete);
		dataForm.append('lessonMedia2Title', this.state.lessonMedia2Title);
		dataForm.append('lessonType3', this.state.lessonType3);
		dataForm.append(
			'lessonMedia3',
			this.state.lessonType3 !== 'file'
				? this.state.lessonMedia3
				: this.state.lessonMedia3File
		);
		dataForm.append('lessonMedia3Title', this.state.lessonMedia3Title);
		dataForm.append('lessonMedia3Delete', this.state.lessonMedia3Delete);

		dataForm.append(
			'lessonWorksheet1',
			this.state.lessonWorksheet1File || this.state.lessonWorksheet1
		);
		dataForm.append('lessonWorksheet1Title', this.state.lessonWorksheet1Title);
		dataForm.append(
			'lessonWorksheet1Delete',
			this.state.lessonWorksheet1Delete
		);
		dataForm.append(
			'lessonWorksheet2',
			this.state.lessonWorksheet2File || this.state.lessonWorksheet2
		);
		dataForm.append('lessonWorksheet2Title', this.state.lessonWorksheet2Title);
		dataForm.append(
			'lessonWorksheet2Delete',
			this.state.lessonWorksheet2Delete
		);

		dataForm.append('lessonVideo1Url', this.state.lessonVideo1Url);
		dataForm.append('lessonVideo1Text', this.state.lessonVideo1Text);
		dataForm.append('lessonVideo2Url', this.state.lessonVideo2Url);
		dataForm.append('lessonVideo2Text', this.state.lessonVideo2Text);
		dataForm.append('lessonVideo3Url', this.state.lessonVideo3Url);
		dataForm.append('lessonVideo3Text', this.state.lessonVideo3Text);

		dataForm.append('lessonStepA', this.state.lessonStepA);
		dataForm.append('lessonStepAPassive', this.state.lessonStepAPassive);
		dataForm.append('lessonStepADescription', this.state.lessonStepADescription);
		dataForm.append('lessonParentAType', this.state.lessonParentAType);
		dataForm.append('lessonParentAFile', this.state.lessonParentAFile);
		dataForm.append('lessonParentAUrl', this.state.lessonParentAUrl);

		dataForm.append('lessonStepB', this.state.lessonStepB);
		dataForm.append('lessonStepBPassive', this.state.lessonStepBPassive);
		dataForm.append('lessonStepBDescription', this.state.lessonStepBDescription);
		dataForm.append('lessonParentBType', this.state.lessonParentBType);
		dataForm.append('lessonParentBFile', this.state.lessonParentBFile);
		dataForm.append('lessonParentBUrl', this.state.lessonParentBUrl);

		dataForm.append('lessonStepC', this.state.lessonStepC);
		dataForm.append('lessonStepCPassive', this.state.lessonStepCPassive);
		dataForm.append('lessonStepCDescription', this.state.lessonStepCDescription);
		dataForm.append('lessonParentCType', this.state.lessonParentCType);
		dataForm.append('lessonParentCFile', this.state.lessonParentCFile);
		dataForm.append('lessonParentCUrl', this.state.lessonParentCUrl);

		dataForm.append('lessonStepD', this.state.lessonStepD);
		dataForm.append('lessonStepDPassive', this.state.lessonStepDPassive);
		dataForm.append('lessonStepDDescription', this.state.lessonStepDDescription);
		dataForm.append('lessonParentDType', this.state.lessonParentDType);
		dataForm.append('lessonParentDFile', this.state.lessonParentDFile);
		dataForm.append('lessonParentDUrl', this.state.lessonParentDUrl);

		dataForm.append('lessonStepE', this.state.lessonStepE);
		dataForm.append('lessonStepEDescription', this.state.lessonStepEDescription);

		this.setState({
			isWaiting: true,
			loaderRequested: true
		});

		axiosPost(url, dataForm, this.props.token, axiosResult => {
			console.log('add edit lesson:', axiosResult);
			if (axiosResult.status === 'success') {
				this.setState(
					{
						isWaiting: false
					},
					() => {
						const loader = document.querySelector('.circleLoader');
						const check = loader.querySelector('.draw');
						loader.classList.add('loadComplete');
						check.classList.add('checkmark');
					}
				);
				this.props.updateData();
				setTimeout(() => {
					this.props.hidepopup();
				}, 500);
			} else {
				this.setState({
					isWaiting: false,
					loaderRequested: false,
					errorMessage: axiosResult.failreason
				});
			}
		});
	};

	render() {
		return (
			<div
				className="LessonAddEditWrapper popupWrapper studentPopupWrapper"
				style={{ opacity: 1, visibility: 'visible' }}
			>
				<div className="popup addEditWrapper">
					<button
						type="button"
						className="closePopup"
						onClick={() => {
							this.props.hidepopup();
						}}
					>
						<Cross width={18} />
					</button>

					{this.props.name === 'add' ? (
						<h2 className="headingStyle">Les toevoegen</h2>
					) : (
						<h2 className="headingStyle">Bewerk les</h2>
					)}

					<ul className="popupTabsWrapper">
						{[
							'Algemeen',
							'Leerbelemmeringen',
							'Lesmateriaal',
							//'Werkbladen',
							//'Video’s en liedjes',
							'Ontwikkelstappen'
						].map((item, i) => (
							<li
								key={`tab_${i}`}
								onClick={() => this.handleTabClick(i)}
								className={i === this.state.activeStep ? 'active' : ''}
							>
								{item}
							</li>
						))}
					</ul>

					{/* general info step */}
					<div className="stepsWrapper step_0">
						<label>Naam les *</label>
						<input
							required
							type="text"
							name="lessonName"
							placeholder="Vul hier de naam van de les in"
							className="inputField"
							value={this.state.lessonName}
							onChange={this.handleInputChange}
						/>

						<label>Omschrijving *</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="lessonIntroduction"
							placeholder="Omschrijf de les in 1 a 2 zinnen"
							value={this.state.lessonIntroduction}
							onChange={this.handleInputChange}
						/>

						<label>Publicatiedatum</label>
						<InputMask
							mask="99-99-9999"
							className="inputField"
							placeholder="dd-mm-jjjj"
							value={this.state.lessonPublicationdate}
							onChange={this.handleDateChange}
						/>

						<div className="selectboxWrapper">
							<div>
								<label>Vak *</label>
								<select
									name="topicId"
									value={this.state.topicId}
									onChange={event => {
										this.handleInputChange(event);
										this.filterGoals(event.target.value);
										if (event.target.value != this.state.topicId) {
											this.setState({
												goalId: 0,
											});
										}
									}}
								>
									<option value="0">Kies vak</option>
									{this.props.topics.map((item, i) => {
										return (
											<option key={`topic_${i}`} value={item.topicId}>
												{item.topicName}
											</option>
										);
									})}
								</select>
							</div>
							{this.state.topicGoals && (
								<div>
									<label>Doel *</label>
									<select
										name="goalId"
										value={this.state.goalId}
										onChange={this.handleInputChange}
									>
										<option value="0">Kies doel</option>
										{this.state.topicGoals.map((item, i) => {
											return (
												<option key={`goal_${i}`} value={item.goalId}>
													{item.goalName}
												</option>
											);
										})}
									</select>
								</div>
							)}
						</div>
					</div>

					{/* blockers/simultants */}
					<div className="stepsWrapper step_1">
						<label>Leerbelemmeringen</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="blockersstimulants_blockers"
							placeholder="Beschrijf hier de leerbelemmeringen"
							value={this.state.blockersstimulants_blockers}
							onChange={this.handleInputChange}
						/>
						<label>Leerstimulansen</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="blockersstimulants_stimulants"
							placeholder="Beschrijf hier de leerstimulansen"
							value={this.state.blockersstimulants_stimulants}
							onChange={this.handleInputChange}
						/>
					</div>

					{/* materials step */}
					<div className="stepsWrapper step_2">
						<label>Materiaal 1</label>
						<div className="row">
							<div
								className={`btnWrapper translateButton ${
									this.state.lessonMedia1 || this.state.lessonMedia1File
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={'active'}
									onClick={() => {
										this.setState({
											lessonType1: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>

							<div className="inputWrapper">
								<>
									<label className="subLabel">
										Upload bestand (pdf, powerpoint)
									</label>
									{this.state.lessonMedia1 ? (
										<input
											readOnly
											type="text"
											className="inputField"
											value="Er is een bestand aanwezig"
										/>
									) : (
										<input
											type="file"
											className="inputField"
											name="lessonMedia1File"
											accept=".ppt,.pptx,.pdf"
											onChange={this.handleFileChange}
										/>
									)}
								</>
								<label className="subLabel">Titel knop</label>
								<input
									readOnly
									type="text"
									className="inputField"
									disabled
									placeholder="Open les"
									name="lessonMedia1Title"
									value="Open les"
								/>
							</div>

							<div className="translateButton">
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonMedia1}
									onClick={() => {
										window.open(this.state.lessonMedia1, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									disabled={
										!this.state.lessonMedia1 && !this.state.lessonMedia1File
									}
									className="outlineButton clearButton"
									onClick={() => {
										this.setState({
											lessonType1: 'link',
											lessonMedia1: '',
											lessonMedia1File: '',
											lessonMedia1Delete: true
										});
										const input = document.querySelector(
											'input[name=lessonMedia1File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>

						{/*
						<label>Materiaal 2</label>
						<div className="row">
							<div
								className={`btnWrapper translateButton ${
									this.state.lessonMedia2 || this.state.lessonMedia2File
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={this.state.lessonType2 === 'link' && 'active'}
									onClick={() => {
										this.setState({
											lessonType2: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={this.state.lessonType2 === 'iframe' && 'active'}
									onClick={() => {
										this.setState({
											lessonType2: 'iframe'
										});
									}}
								>
									iframe
								</button>
								<button
									className={(this.state.lessonType2 === 'file' || !this.state.lessonType2) && 'active'}
									onClick={() => {
										this.setState({
											lessonType2: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>

							<div className="inputWrapper">
								{this.state.lessonType2 === 'link' || this.state.lessonType2 === 'iframe' ? (
									<>
										<label className="subLabel">
											{this.state.lessonType2 === 'link' ? <>
												Plak hier de url van een YouTube-video of een Canva-link.
												Gebruik hiervoor de 'insluiten' / 'embed' link.
											</> : <>
												Plak hier de url voor het iframe.
											</>}
										</label>
										<input
											type="url"
											name="lessonMedia2"
											className="inputField"
											placeholder="https://www.youtube.com/..."
											value={this.state.lessonMedia2}
											onChange={this.handleInputChange}
										/>
									</>
								) : (
									<>
										<label className="subLabel">
											Upload bestand (pdf, powerpoint)
										</label>
										{this.state.lessonMedia2 ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												name="lessonMedia2File"
												accept=".ppt,.pptx,.pdf"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
								<label className="subLabel">Titel knop</label>
								<input
									type="text"
									name="lessonMedia2Title"
									className="inputField"
									placeholder="Open les"
									value={this.state.lessonMedia2Title}
									onChange={this.handleInputChange}
								/>
							</div>

							<div className="translateButton">
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonMedia2}
									onClick={() => {
										window.open(this.state.lessonMedia2, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									disabled={
										!this.state.lessonMedia2 && !this.state.lessonMedia2File
									}
									className="outlineButton clearButton"
									onClick={() => {
										this.setState({
											lessonMedia2: '',
											lessonType2: 'link',
											lessonMedia2File: '',
											lessonMedia2Title: '',
											lessonMedia2Delete: true
										});
										const input = document.querySelector(
											'input[name=lessonMedia2File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>

						<label>Materiaal 3</label>
						<div className="row">
							<div
								className={`btnWrapper translateButton ${
									this.state.lessonMedia3 || this.state.lessonMedia3File
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={this.state.lessonType3 === 'link' && 'active'}
									onClick={() => {
										this.setState({
											lessonType3: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={this.state.lessonType3 === 'iframe' && 'active'}
									onClick={() => {
										this.setState({
											lessonType3: 'iframe'
										});
									}}
								>
									iframe
								</button>
								<button
									className={(this.state.lessonType3 === 'file' || !this.state.lessonType3) && 'active'}
									onClick={() => {
										this.setState({
											lessonType3: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>

							<div className="inputWrapper">
								{this.state.lessonType3 === 'link' || this.state.lessonType3 === 'iframe' ? (
									<>
										<label className="subLabel">
											{this.state.lessonType3 === 'link' ? <>
												Plak hier de url van een YouTube-video of een Canva-link.
												Gebruik hiervoor de 'insluiten' / 'embed' link.
											</> : <>
												Plak hier de url voor het iframe.
											</>}
										</label>
										<input
											type="url"
											name="lessonMedia3"
											className="inputField"
											placeholder="https://www.youtube.com/..."
											value={this.state.lessonMedia3}
											onChange={this.handleInputChange}
										/>
									</>
								) : (
									<>
										<label className="subLabel">
											Upload bestand (pdf, powerpoint)
										</label>
										{this.state.lessonMedia3 ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												name="lessonMedia3File"
												accept=".ppt,.pptx,.pdf"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
								<label className="subLabel">Titel knop</label>
								<input
									type="text"
									name="lessonMedia3Title"
									className="inputField"
									placeholder="Open les"
									value={this.state.lessonMedia3Title}
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="translateButton">
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonMedia3}
									onClick={() => {
										window.open(this.state.lessonMedia3, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									disabled={
										!this.state.lessonMedia3 && !this.state.lessonMedia3File
									}
									className="outlineButton clearButton"
									onClick={() => {
										this.setState({
											lessonMedia3: '',
											lessonType3: 'link',
											lessonMedia3File: '',
											lessonMedia3Title: '',
											lessonMedia3Delete: true
										});
										const input = document.querySelector(
											'input[name=lessonMedia3File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>
						*/}
					</div>

					{/* worksheet step */}
					{/*
					<div className="stepsWrapper step_3">
						<label>Werkblad 1</label>
						<div className="row">
							<div className="inputWrapper">
								{this.state.lessonWorksheet1 ? (
									<input
										readOnly
										type="text"
										className="inputField"
										value="Er is een bestand aanwezig"
									/>
								) : (
									<>
										<input
											type="file"
											className="inputField"
											accept=".ppt,.pptx,.pdf"
											name="lessonWorksheet1File"
											onChange={this.handleFileChange}
										/>
									</>
								)}
								<label className="subLabel">Titel</label>
								<input
									type="text"
									className="inputField"
									name="lessonWorksheet1Title"
									placeholder="Korte titel van het werkblad"
									value={this.state.lessonWorksheet1Title}
									onChange={this.handleInputChange}
								/>
							</div>
							<div>
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonWorksheet1}
									onClick={() => {
										window.open(this.state.lessonWorksheet1, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.lessonWorksheet1 &&
										!this.state.lessonWorksheet1File
									}
									onClick={() => {
										this.setState({
											lessonWorksheet1: '',
											lessonWorksheet1File: '',
											lessonWorksheet1Title: '',
											lessonWorksheet1Delete: true
										});
										const input = document.querySelector(
											'input[name=lessonWorksheet1File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>

						<label>Werkblad 2</label>
						<div className="row">
							<div className="inputWrapper">
								{this.state.lessonWorksheet2 ? (
									<input
										readOnly
										type="text"
										className="inputField"
										value="Er is een bestand aanwezig"
									/>
								) : (
									<>
										<input
											type="file"
											className="inputField"
											accept=".ppt,.pptx,.pdf"
											name="lessonWorksheet2File"
											onChange={this.handleFileChange}
										/>
									</>
								)}
								<label className="subLabel">Titel</label>
								<input
									type="text"
									className="inputField"
									name="lessonWorksheet2Title"
									placeholder="Korte titel van het werkblad"
									value={this.state.lessonWorksheet2Title}
									onChange={this.handleInputChange}
								/>
							</div>
							<div>
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonWorksheet2}
									onClick={() => {
										window.open(this.state.lessonWorksheet2, '_blank');
									}}
								>
									bekijk
								</button>
								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.lessonWorksheet2 &&
										!this.state.lessonWorksheet2File
									}
									onClick={() => {
										this.setState({
											lessonWorksheet2: '',
											lessonWorksheet2File: '',
											lessonWorksheet2Title: '',
											lessonWorksheet2Delete: true
										});
										const input = document.querySelector(
											'input[name=lessonWorksheet2File]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>
					</div>
					*/}

					{/* videos step */}
					{/*
					<div className="stepsWrapper step_4">
						<label>Video / liedje 1</label>
						<label className="subLabel">
							Plak hier de url van een YouTube-video of een Canva-link. Gebruik
							hiervoor de 'insluiten' / 'embed' link.
						</label>
						<input
							type="text"
							name="lessonVideo1Url"
							className="inputField"
							placeholder="https://www.youtube.com/..."
							value={this.state.lessonVideo1Url}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Titel</label>
						<input
							type="text"
							name="lessonVideo1Text"
							className="inputField"
							placeholder="Korte titel van de video / het liedje"
							value={this.state.lessonVideo1Text}
							onChange={this.handleInputChange}
						/>

						<label>Video / liedje 2</label>
						<label className="subLabel">
							Plak hier de url van een YouTube-video of een Canva-link. Gebruik
							hiervoor de 'insluiten' / 'embed' link.
						</label>
						<input
							type="text"
							name="lessonVideo2Url"
							className="inputField"
							placeholder="https://www.youtube.com/..."
							value={this.state.lessonVideo2Url}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Titel</label>
						<input
							type="text"
							name="lessonVideo2Text"
							className="inputField"
							placeholder="Korte titel van de video / het liedje"
							value={this.state.lessonVideo2Text}
							onChange={this.handleInputChange}
						/>

						<label>Video / liedje 3</label>
						<label className="subLabel">
							Plak hier de url van een YouTube-video of een Canva-link. Gebruik
							hiervoor de 'insluiten' / 'embed' link.
						</label>
						<input
							type="text"
							name="lessonVideo3Url"
							className="inputField"
							placeholder="https://www.youtube.com/..."
							value={this.state.lessonVideo3Url}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Titel</label>
						<input
							type="text"
							name="lessonVideo3Text"
							className="inputField"
							placeholder="Korte titel van de video / het liedje"
							value={this.state.lessonVideo3Text}
							onChange={this.handleInputChange}
						/>
					</div>
					*/}

					{/* student steps step */}
					<div className="stepsWrapper step_3">
						<label>Ontwikkelstap A</label>
						<label className="subLabel">
							Omschrijving (actief). Gebruik de code #KIND om de voornaam van de
							leerling aan te duiden.
						</label>
						<input
							type="text"
							name="lessonStepA"
							className="inputField"
							placeholder="#KIND leert naast de fiets staan."
							value={this.state.lessonStepA}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Omschrijving (passief)</label>
						<input
							type="text"
							className="inputField"
							name="lessonStepAPassive"
							placeholder="#KIND kan naast de fiets staan."
							onChange={this.handleInputChange}
							value={this.state.lessonStepAPassive}
						/>
						<label className="subLabel">Omschrijving Rapport</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="lessonStepADescription"
							placeholder=""
							onChange={this.handleInputChange}
							value={this.state.lessonStepADescription}
						/>
						{/*
						<label className="subLabel">Ondersteunende les ouders</label>
						<div className="row">
							<div
								className={`btnWrapper ${
									this.state.lessonParentAUrl || this.state.lessonParentAFile
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={this.state.lessonParentAType === 'link' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentAType: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={this.state.lessonParentAType === 'iframe' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentAType: 'iframe'
										});
									}}
								>
									iframe
								</button>
								<button
									className={(this.state.lessonParentAType === 'file' || !this.state.lessonParentAType) && 'active'}
									onClick={() => {
										this.setState({
											lessonParentAType: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>
							<div className="inputWrapper">
								{this.state.lessonParentAType === 'link' || this.state.lessonParentAType === 'iframe' ? (
									<input
										type="url"
										name="lessonParentAUrl"
										className="inputField"
										onChange={this.handleInputChange}
										value={this.state.lessonParentAUrl}
									/>
								) : (
									<>
										{this.state.lessonParentAUrl ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												accept=".ppt,.pptx,.pdf"
												name="lessonParentAFile"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
							</div>
							<div>
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonParentAUrl}
									onClick={() => {
										window.open(this.state.lessonParentAUrl, '_blank');
									}}
								>
									bekijk
								</button>

								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.lessonParentAUrl &&
										!this.state.lessonParentAFile
									}
									onClick={() => {
										this.setState({
											lessonParentAUrl: '',
											lessonParentAFile: ''
										});
										const input = document.querySelector(
											'input[name=lessonParentAFile]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>
						*/}

						<label>Ontwikkelstap B</label>
						<label className="subLabel">Omschrijving (actief).</label>
						<input
							type="text"
							name="lessonStepB"
							className="inputField"
							placeholder="#KIND leert opstoppen en wegfietsen op de fiets"
							value={this.state.lessonStepB}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Omschrijving (passief).</label>
						<input
							type="text"
							className="inputField"
							name="lessonStepBPassive"
							placeholder="#KIND kan opstappen en wegfietsen met de fiets"
							onChange={this.handleInputChange}
							value={this.state.lessonStepBPassive}
						/>
						<label className="subLabel">Omschrijving Rapport</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="lessonStepBDescription"
							placeholder=""
							onChange={this.handleInputChange}
							value={this.state.lessonStepBDescription}
						/>
						{/*
						<label className="subLabel">Ondersteunende les ouders</label>
						<div className="row">
							<div
								className={`btnWrapper ${
									this.state.lessonParentBUrl || this.state.lessonParentBFile
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={this.state.lessonParentBType === 'link' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentBType: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={this.state.lessonParentBType === 'iframe' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentBType: 'iframe'
										});
									}}
								>
									iframe
								</button>
								<button
									className={(this.state.lessonParentBType === 'file' || !this.state.lessonParentBType) && 'active'}
									onClick={() => {
										this.setState({
											lessonParentBType: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>
							<div className="inputWrapper">
								{this.state.lessonParentBType === 'link' || this.state.lessonParentBType === 'iframe' ? (
									<input
										type="url"
										name="lessonParentBUrl"
										className="inputField"
										onChange={this.handleInputChange}
										value={this.state.lessonParentBUrl}
									/>
								) : (
									<>
										{this.state.lessonParentBUrl ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												accept=".ppt,.pptx,.pdf"
												name="lessonParentBFile"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
							</div>
							<div>
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonParentBUrl}
									onClick={() => {
										window.open(this.state.lessonParentBUrl, '_blank');
									}}
								>
									bekijk
								</button>

								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.lessonParentBUrl &&
										!this.state.lessonParentBFile
									}
									onClick={() => {
										this.setState({
											lessonParentBUrl: '',
											lessonParentBFile: ''
										});
										const input = document.querySelector(
											'input[name=lessonParentBFile]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>
						*/}

						<label>Ontwikkelstap C</label>
						<label className="subLabel">Omschrijving (actief).</label>
						<input
							type="text"
							name="lessonStepC"
							className="inputField"
							placeholder="#KIND leert bochten fietsen"
							value={this.state.lessonStepC}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Omschrijving (passief).</label>
						<input
							type="text"
							className="inputField"
							name="lessonStepCPassive"
							placeholder="#KIND kan bochten fietsen"
							onChange={this.handleInputChange}
							value={this.state.lessonStepCPassive}
						/>
						<label className="subLabel">Omschrijving Rapport</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="lessonStepCDescription"
							placeholder=""
							onChange={this.handleInputChange}
							value={this.state.lessonStepCDescription}
						/>
						{/*
						<label className="subLabel">Ondersteunende les ouders</label>
						<div className="row">
							<div
								className={`btnWrapper ${
									this.state.lessonParentCUrl || this.state.lessonParentCFile
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={this.state.lessonParentCType === 'link' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentCType: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={this.state.lessonParentCType === 'iframe' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentCType: 'iframe'
										});
									}}
								>
									iframe
								</button>
								<button
									className={(this.state.lessonParentCType === 'file' || !this.state.lessonParentCType) && 'active'}
									onClick={() => {
										this.setState({
											lessonParentCType: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>
							<div className="inputWrapper">
								{this.state.lessonParentCType === 'link' || this.state.lessonParentCType === 'iframe' ? (
									<input
										type="url"
										name="lessonParentCUrl"
										className="inputField"
										onChange={this.handleInputChange}
										value={this.state.lessonParentCUrl}
									/>
								) : (
									<>
										{this.state.lessonParentCUrl ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												accept=".ppt,.pptx,.pdf"
												name="lessonParentCFile"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
							</div>
							<div>
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonParentCUrl}
									onClick={() => {
										window.open(this.state.lessonParentCUrl, '_blank');
									}}
								>
									bekijk
								</button>

								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.lessonParentCUrl &&
										!this.state.lessonParentCFile
									}
									onClick={() => {
										this.setState({
											lessonParentCUrl: '',
											lessonParentCFile: ''
										});
										const input = document.querySelector(
											'input[name=lessonParentCFile]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>
						*/}

						<label>Ontwikkelstap D</label>
						<label className="subLabel">Omschrijving (actief).</label>
						<input
							type="text"
							name="lessonStepD"
							className="inputField"
							placeholder="#KIND leert een noodstop maken met de fiets"
							value={this.state.lessonStepD}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Omschrijving (passief).</label>
						<input
							type="text"
							className="inputField"
							name="lessonStepDPassive"
							placeholder="#KIND kan een noodstop maken met de fiets"
							onChange={this.handleInputChange}
							value={this.state.lessonStepDPassive}
						/>
						<label className="subLabel">Omschrijving Rapport</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="lessonStepDDescription"
							placeholder=""
							onChange={this.handleInputChange}
							value={this.state.lessonStepDDescription}
						/>
						{/*
						<label className="subLabel">Ondersteunende les ouders</label>
						<div className="row">
							<div
								className={`btnWrapper ${
									this.state.lessonParentDUrl || this.state.lessonParentDFile
										? 'disableButton'
										: ''
								}`}
							>
								<button
									className={this.state.lessonParentDType === 'link' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentDType: 'link'
										});
									}}
								>
									link
								</button>
								<button
									className={this.state.lessonParentDType === 'iframe' && 'active'}
									onClick={() => {
										this.setState({
											lessonParentDType: 'iframe'
										});
									}}
								>
									iframe
								</button>
								<button
									className={(this.state.lessonParentDType === 'file' || !this.state.lessonParentDType) && 'active'}
									onClick={() => {
										this.setState({
											lessonParentDType: 'file'
										});
									}}
								>
									bestand
								</button>
							</div>
							<div className="inputWrapper">
								{this.state.lessonParentDType === 'link' || this.state.lessonParentDType === 'iframe' ? (
									<input
										type="url"
										name="lessonParentDUrl"
										className="inputField"
										onChange={this.handleInputChange}
										value={this.state.lessonParentDUrl}
									/>
								) : (
									<>
										{this.state.lessonParentDUrl ? (
											<input
												readOnly
												type="text"
												className="inputField"
												value="Er is een bestand aanwezig"
											/>
										) : (
											<input
												type="file"
												className="inputField"
												accept=".ppt,.pptx,.pdf"
												name="lessonParentDFile"
												onChange={this.handleFileChange}
											/>
										)}
									</>
								)}
							</div>
							<div>
								<button
									type="button"
									className="defaultBtn"
									disabled={!this.state.lessonParentDUrl}
									onClick={() => {
										window.open(this.state.lessonParentDUrl, '_blank');
									}}
								>
									bekijk
								</button>

								<button
									type="button"
									className="outlineButton clearButton"
									disabled={
										!this.state.lessonParentDUrl &&
										!this.state.lessonParentDFile
									}
									onClick={() => {
										this.setState({
											lessonParentDUrl: '',
											lessonParentDFile: ''
										});
										const input = document.querySelector(
											'input[name=lessonParentDFile]'
										);
										if (input) {
											input.value = '';
										}
									}}
								>
									<Cross width={14} />
								</button>
							</div>
						</div>
						*/}

						<label>Ontwikkelstap E</label>
						<label className="subLabel">Omschrijving (actief).</label>
						<input
							type="text"
							name="lessonStepE"
							className="inputField"
							placeholder="#KIND kan zelfstandig fietsen"
							value={this.state.lessonStepE}
							onChange={this.handleInputChange}
						/>
						<label className="subLabel">Omschrijving Rapport</label>
						<textarea
							required
							type="text"
							className="inputField"
							name="lessonStepEDescription"
							placeholder=""
							onChange={this.handleInputChange}
							value={this.state.lessonStepEDescription}
						/>
						
					</div>

					{this.state.errorMessage && (
						<div className="error">* {this.state.errorMessage}</div>
					)}

					<div className="row buttonsWrapper">
						{this.props.name === 'edit' ? (
							<div>
								<button
									type="button"
									className="outlineButton inlineBlock"
									onClick={this.openDeletePopup}
									style={{ marginRight: '15px' }}
								>
									Verwijderen
								</button>
								<button
									type="button"
									className="outlineButton inlineBlock"
									style={{ marginRight: '15px' }}
									onClick={() =>
										this.handleArchive(
											this.props.popupdata.lesson?.general.lessonActive == '1'
												? 'archive'
												: 'unarchive'
										)
									}
								>
									{this.props.popupdata.lesson?.general.lessonActive == '1'
										? 'Archiveer'
										: 'Activeer'}
								</button>
							</div>
						) : (
							<div />
						)}
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="outlineButton inlineBlock"
								onClick={this.props.hidepopup}
							>
								Annuleren
							</button>
							<button
								type="button"
								className="defaultBtn inlineBlock"
								onClick={this.submitrequest}
								style={{ marginLeft: '15px' }}
							>
								Opslaan
							</button>
						</div>
					</div>
				</div>

				<div className="popup deleteWrapper">
					<p
						dangerouslySetInnerHTML={{
							__html: this.props.popupdata.lesson?.deleteconfirmation.replace(
								/\n/g,
								'<br />'
							)
						}}
					></p>

					<div className="row">
						<div />
						<div>
							{this.state.loaderRequested && (
								<div className="circleLoader">
									<div className="draw" />
								</div>
							)}
							<button
								type="button"
								className="inlineBlock outlineButton "
								onClick={this.hideDeletePopup}
							>
								Annuleren
							</button>

							<button
								type="button"
								className="inlineBlock defaultBtn"
								onClick={this.handleDelete}
								style={{ marginLeft: '15px' }}
							>
								Ok
							</button>
						</div>
					</div>
				</div>

				{this.state.imagePath && (
					<ImageEditor
						fileName={this.state.imagePath.name}
						src={URL.createObjectURL(this.state.imagePath)}
						handleClose={() => {
							this.setState({
								imagePath: null
							});
							this.inputFile.value = '';
						}}
						callback={this.croppedImageCallback}
					/>
				)}
				<span className="overlayBackground" />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	topics: state.initialData.topics,
});

export default connect(mapStateToProps, {
	sendErrorToHOC
})(LessonAddEdit);
